import React, { useRef } from 'react';

import { useHistoricoModulos } from '../../../hooks/useHistoricoModulos';

import '../styles/filtros.scss';

export const FiltrosResumen = ({ setPlanta, opciones, orden, setOrden, setFiltro, datos }) => {
    const { descargarXLSX } = useHistoricoModulos(); 
    const select_planta = useRef( null );
    const select_col = useRef( null );
    
    return (
        <div className="fila-sup-resumen">
            <div className="filtros resumen">
                <div className='filtro-select'>
                    <label htmlFor="select-planta">Planta</label>
                    <select 
                        id="select-planta" 
                        className="browser-default" 
                        ref={ select_planta } 
                        onChange={ () => { setPlanta( parseInt((select_planta.current).value) ) }}
                    >
                        <option value="2">OXE</option>
                        <option value="1">MET</option>
                    </select>
                </div>
                
                <div className='filtro-select'>
                    <label htmlFor="select-cols">Ordenar por</label>
                    <select 
                        id="select-cols" 
                        className="browser-default" 
                        ref={ select_col } 
                        onChange={ () => { setFiltro( (select_col.current).value ) }}
                    >
                        {
                            opciones.map(({ nombre, llave }, index) => (
                                <option value={ llave } key={ index }>{ nombre }</option>
                            ))
                        }
                    </select>
                </div>

                <div className="btn-orden">
                    <label>{ orden }</label> <br />
                    <button className='waves-effect waves-light btn deep-orange lighten-2' onClick={ () => { setOrden( orden == 'Ascendente' ? 'Descendente' : 'Ascendente' ) }}>
                        <i className="material-icons left">sync_alt</i>
                    </button>
                </div>
            </div>

            <button 
                className="waves-effect waves-light btn-dl btn light-blue darken-4"
                onClick={ () => { descargarXLSX( datos, opciones, 'Resumen módulos', ( (select_planta.current).options[(select_planta.current).selectedIndex].text ) ) }}
            >
                <i className="material-icons left">download</i>
                DESCARGAR
            </button>
        </div>
    )
}
