import historicoAPI from '../../API/HistoricoAPI';
import { useSelector } from "react-redux";
import smartplsAPI from '../../API/smartplsAPI';

export const useHistoricoModulos = () => {
    const { token } = useSelector((state) => state.autentificacion);

    const moment = require('moment');
    const XLSX = require("xlsx-js-style");

    const obtenerResumenActual = async( planta ) => {
        let datos = [];
        const llaves = [
            { clases: 'min', tipo: 'nomenclatura', nombre: 'Módulo', llave: 'id_modulo_operacion', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Detenido por fuerza mayor', llave: 'fecha_detenido_por_fuerza_mayor', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Vacío', llave: 'fecha_vacio', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Inicio apilamiento', llave: 'fecha_inicio_apilamiento', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Fin apilamiento', llave: 'fecha_fin_apilamiento', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Armado parrilla', llave: 'fecha_armado_parrilla', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Ruteo', llave: 'fecha_ruteo', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Humectación', llave: 'fecha_humectacion', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Riego', llave: 'fecha_riego', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Drenaje', llave: 'fecha_drenaje', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Desarme parrilla', llave: 'fecha_desarme_de_parrilla', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Inicio derripado', llave: 'fecha_inicio_derripado', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Fin derripado', llave: 'fecha_fin_derripado', unidad: '' },
            { clases: 'min', tipo: 'num', nombre: 'Área', llave: 'area', unidad: '[m2]' },
            { clases: 'min', tipo: 'num', nombre: 'Áltura', llave: 'altura', unidad: '[m]' },
            { clases: 'min', tipo: 'num', nombre: 'CO3', llave: 'co3', unidad: '' },
            { clases: 'min', tipo: 'num', nombre: 'Húmedad', llave: 'humedad', unidad: '[%]' },
            { clases: 'min', tipo: 'num', nombre: 'Ley total', llave: 'leytotal', unidad: '[%]' },
            { clases: 'min', tipo: 'num', nombre: 'Ley soluble', llave: 'leysoluble', unidad: '[%]' },
            { clases: 'max', tipo: 'num', nombre: 'Masa húmeda', llave: 'masahumeda', unidad: '[t]' },
            { clases: 'max', tipo: 'num', nombre: 'H real consumido', llave: 'h_real_consumido', unidad: '[t]' },
            { clases: 'max', tipo: 'num', nombre: 'Glanurometria 100', llave: 'glanurometria_100', unidad: '' },
            { clases: 'max', tipo: 'num', nombre: 'Glanurometria 400', llave: 'glanurometria_400', unidad: '' },
            { clases: 'max', tipo: 'num', nombre: 'Glanurometria 1/2', llave: 'glanurometria_unmedio', unidad: '' },
            { clases: 'min', tipo: 'num', nombre: 'Recup. esperada', llave: 'recuperacion_esperada', unidad: '[%]' },
            { clases: 'min', tipo: 'num', nombre: 'NO3', llave: 'no3', unidad: '' },
            { clases: 'max', tipo: 'num', nombre: 'H consumido en aglomerado', llave: 'hconsumido_en_aglomerado', unidad: '[t]' },
            { clases: 'max', tipo: 'num', nombre: 'Agua consumido en aglomerado', llave: 'agua_consumido_en_aglomerado', unidad: '[t]' },
            { clases: 'max', tipo: 'num', nombre: 'Rlix alcanzado', llave: 'rlix_alcanzado', unidad: '[m3/tms]' },
            { clases: 'max', tipo: 'num', nombre: 'Rlix esperado', llave: 'rlix_esperado', unidad: '[m3/tms]' },
            { clases: 'max', tipo: 'num', nombre: 'Recup. alcanzada hrs riego', llave: 'recuperacion_alcanzada_h_riego', unidad: '[%]' },
            { clases: 'max', tipo: 'num', nombre: 'Recup. estimada hrs riego', llave: 'recuperacion_esperada_h_riego', unidad: '[%]' },
            { clases: 'max', tipo: 'num', nombre: 'Recup. alcanzada Rlix', llave: 'recuperacion_alcanzada_rlix', unidad: '[%]' },
            { clases: 'min', tipo: 'num', nombre: 'Total hrs riego', llave: 'horas_total_riego', unidad: '[hrs]' }
        ];
        // commit
        try {
            const response = await fetch('https://historico-centinela.smartpls.cl/get-data/resume/'+planta, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            });

            const { data } = await response.json();
            datos = data;
            console.log(datos);

        } catch( err ) {
            console.log(err);
        }
        return {
            llaves,
            datos: ordenarArray( 'id_modulo_operacion', llaves, datos, 'Descendente' )
        }
    }
    
    const obtenerCiclosPlantaAPI = async( planta ) => {
        const { data } = await smartplsAPI.get(`/entradasManuales/EntradasManuales_Lectura.php?Consulta=obtener_ciclos&id_planta=${ planta }`);
        return (data.map( item => item.ciclo )).slice(0,2);
    }

    const obtenerHistoricoAPI = async( planta, ciclo ) => {
        try {
            const response = await fetch(`https://historico-centinela.smartpls.cl/get-data-ciclo?ciclo=${ ciclo }&planta=${ planta }`, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            });

            const { data } = await response.json();
            const pilas = obtenerPilas( data );

            return { pilas, data };

        } catch( err ) {
            console.log(err);
        }
    }

    const obtenerPilas= ( datos ) => {
        const lista = datos.map( modulo =>  modulo.pila_nombre );
        return (lista.filter( function( nombre, pos ) { return lista.indexOf( nombre ) == pos }));
    }

    const formatoNum = ( num ) => {
        if( num == 0 ) {
            return '0'
        } else {
            let nuevo_num = (num.toFixed(2)).toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            nuevo_num = nuevo_num.includes(',00') ? nuevo_num.replace(',00', '') : nuevo_num;
            return nuevo_num;
        }
    }
    
    function ordenarArray( llave, llaves, datos, orden ) {
        const tipo = (llaves.filter( c => c.llave == llave))[0].tipo;
        const lista_filtrada = datos.filter( r =>  r[llave] != null );
        const lista_nulos = datos.filter( r =>  r[llave] == null );

        let nueva = [];

        switch( tipo ) {
            case 'fecha':
                nueva = lista_filtrada.sort((a,b) => {
                    return new Date(moment(b[llave], 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')) - new Date( moment(a[llave], 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'))
                });
                // Agregar nulos
                nueva = [ ...nueva, 
                    ... lista_nulos
                ];

                if( orden == 'Ascendente' ) {
                    nueva = nueva.reverse()
                }
                break;

            case 'num':
                nueva = lista_filtrada.sort((a,b) => a[llave] - b[llave]);
                nueva = [ ...nueva, 
                    ... lista_nulos
                ];

                if( orden != 'Ascendente' ) {
                    nueva = nueva.reverse()
                }
                break;

            case 'texto':
                nueva = lista_filtrada.sort((a,b) => 
                    ( (a[llave]) >  (b[llave]) ) 
                    ? 1 : 
                    ( ( (b[llave]) >  (a[llave]) ) 
                        ? -1 : 0)
                );

                nueva = [ ...nueva, 
                    ... lista_nulos
                ];

                if( orden == 'Ascendente' ) {
                    nueva = nueva.reverse()
                }
                break;

            case 'nomenclatura':
                nueva = lista_filtrada.sort((a,b) => 
                    ( parseInt(obtenerNomenclatura( a.nomenclatura, a.ciclo, a.nombre_pila, a.id_modulo_operacion )) >  parseInt(obtenerNomenclatura( b.nomenclatura, b.ciclo, b.nombre_pila, b.id_modulo_operacion )) ) 
                    ? 1 : 
                    ( ( parseInt(obtenerNomenclatura( b.nomenclatura, b.ciclo, b.nombre_pila, b.id_modulo_operacion )) >  parseInt(obtenerNomenclatura( a.nomenclatura, a.ciclo, a.nombre_pila, a.id_modulo_operacion )) ) 
                        ? -1 : 0)
                );

                nueva = [ ...nueva, 
                    ... lista_nulos
                ];

                if( orden != 'Ascendente' ) {
                    nueva = nueva.reverse()
                }
                break;
        }  

        return nueva;
    }

    const obtenerPila = ( pila ) => {
        if( pila.includes("PILA") ) {
            return ''
        } else {
            const num = (pila.split(" "))[1]
    
            return num < 10 ? `0${num}` : num;
        }
    }

    const obtenerNumModulo = ( nombre, pila ) => {
        if( pila.includes("PILA") ) {
            return nombre.length == 1 ? `0${ nombre }` : nombre;
        } else {
            const num = (nombre[1]);
            const letra = nombre[0];
    
            if( letra == 'b' || letra == 'B' ) {
                return parseInt(num) + 4;
            } else {
                return num;
            }
        }
    }
    
    const descargarXLSX = ( filas, cabecera, tipo, planta, ciclo ) => {
        const hoja = generarHoja( cabecera, filas );
        const archivo = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet( archivo, hoja, tipo );
        let nombre = '';
        if( tipo == 'Histórico módulos' ) {
            nombre =`${ tipo.replace(/ /g, "_") }_${ planta }_ciclo_${ ciclo }.xlsx`;
        } else {
            nombre = `${ tipo.replace(/ /g, "_") }_${ planta }_${ moment().format('DD-MM-YYYY') }.xlsx`;
        }

        XLSX.writeFile(archivo, nombre, {  editable: true });
    }

    const generarHoja = ( cabecera, filas ) => {
        // Crear variable hoja
        const hoja = XLSX.utils.aoa_to_sheet([]);
        let origen = 1;

        // Variables de celdas
        let alto_filas = [ { 'hpt': 15 } ];
        let ancho_cols = [ { wch: 3 } ];

        let fila = [ {} ];
        cabecera.forEach( ({ nombre, unidad }) => {
            ancho_cols = [ ...ancho_cols, { wch: 20 } ];
            fila = [ ...fila, { v: `${ nombre } ${ unidad }`, t: 's', s: obtenerEstilosCelda( 'cabecera', false ) }];
        });

        XLSX.utils.sheet_add_aoa(hoja, [ fila ], { origin: origen  });
        origen += 1;

        let oscuro = false;
        filas.forEach(( modulo ) => {
            fila = [ {} ];
            cabecera.forEach(({ llave, tipo }) => {
                if( modulo[llave] == '-' || modulo[llave] == null ) {
                    fila = [ ...fila, { v: '-', t: 's', s: obtenerEstilosCelda( 'fila', oscuro ) }];
                } else {
                    switch( tipo ) {
                        case 'nomenclatura':
                            fila = [ ...fila, { v: obtenerNomenclatura( modulo.nomenclatura, modulo.ciclo, modulo.nombre_pila, modulo.id_modulo_operacion ), t: 's', s: obtenerEstilosCelda( 'fila', oscuro ) }];
                            break;
    
                        case 'fecha':
                            fila = [ ...fila, { v: moment( modulo[llave], 'DD-MM-YYYY HH:mm:ss' ).format( 'DD-MM-YYYY HH:mm'), t: 's', s: obtenerEstilosCelda( 'fila', oscuro ) }];
                            break;
    
                        case 'num':
                            fila = [ ...fila, { v: parseFloat(modulo[llave]), t: 'n', s: obtenerEstilosCelda( 'fila', oscuro ) }];
                            break;
    
                        case 'texto':
                            fila = [ ...fila, { v: modulo[ llave ], t: 's', s: obtenerEstilosCelda( 'fila', oscuro ) }];
                            break;
                    }
                }
            });
            XLSX.utils.sheet_add_aoa(hoja, [ fila ], { origin: origen });
            origen += 1;

            oscuro = !oscuro;
        });

        hoja['!cols'] = ancho_cols;
        hoja['!rows'] = alto_filas;

        return hoja;

    }

    const obtenerNomenclatura = ( nomenclatura, ciclo, pila, modulo) => {
        if( nomenclatura != undefined ) {
            return nomenclatura;
        } else {
            return `${ ciclo }${ obtenerPila( pila )}${ obtenerNumModulo( modulo, pila ) }`
        }
    }
    
    const obtenerEstilosCelda = ( tipo, gris ) => {
        switch( tipo ) {
            case 'cabecera':
                return {
                    font: {
                        sz: 12, // tamaño
                        bold: true, // grosor
                        color: {
                            rgb: 'ffffff'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb: '6f8e9e'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
    
            case 'fila':
                return {
                    font: {
                        sz: 11, // tamaño
                        bold: false, // grosor
                        color: {
                            rgb: '333333'  // color de letra
                        }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: true
                    },
                    fill: {
                        fgColor: {
                            rgb:  gris ? 'f0f0f0' : 'fafafa'
                        }
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FFFFFF'
                            }
                        }
                    }
                }
            
        }
    }

    const filtrarDatos = ( datos, pilas, orden ) => {
        const llaves = [
            { clases: 'min', tipo: 'nomenclatura', nombre: 'Módulo', llave: 'id_modulo', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Detenido por fuerza mayor', llave: 'fecha_detenido_por_fuerza_mayor', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Vacío', llave: 'fecha_vacio', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Inicio apilamiento', llave: 'fecha_inicio_apilamiento', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Fin apilamiento', llave: 'fecha_fin_apilamiento', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Armado parrilla', llave: 'fecha_armado_parrilla', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Ruteo', llave: 'fecha_ruteo', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Humectación', llave: 'fecha_humectacion', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Riego', llave: 'fecha_riego', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Drenaje', llave: 'fecha_drenaje', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Desarme parrilla', llave: 'fecha_desarme_de_parrilla', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Inicio derripado', llave: 'fecha_inicio_derripado', unidad: '' },
            { clases: 'max', tipo: 'fecha', nombre: 'Fin derripado', llave: 'fecha_fin_derripado', unidad: '' },
            { clases: 'min', tipo: 'num', nombre: 'Área', llave: 'area', unidad: '[m2]' },
            { clases: 'min', tipo: 'num', nombre: 'Áltura', llave: 'altura', unidad: '[m]' },
            { clases: 'min', tipo: 'num', nombre: 'CO3', llave: 'co3', unidad: '' },
            { clases: 'min', tipo: 'num', nombre: 'Húmedad', llave: 'humedad', unidad: '[%]' },
            { clases: 'min', tipo: 'num', nombre: 'Ley total', llave: 'leytotal', unidad: '[%]' },
            { clases: 'min', tipo: 'num', nombre: 'Ley soluble', llave: 'leysoluble', unidad: '[%]' },
            { clases: 'max', tipo: 'num', nombre: 'Masa húmeda', llave: 'masahumeda', unidad: '[t]' },
            { clases: 'max', tipo: 'num', nombre: 'H real consumido', llave: 'h_real_consumido', unidad: '[t]' },
            { clases: 'max', tipo: 'num', nombre: 'Glanurometria 100', llave: 'glanurometria_100', unidad: '' },
            { clases: 'max', tipo: 'num', nombre: 'Glanurometria 400', llave: 'glanurometria_400', unidad: '' },
            { clases: 'max', tipo: 'num', nombre: 'Glanurometria 1/2', llave: 'glanurometria_unmedio', unidad: '' },
            { clases: 'min', tipo: 'num', nombre: 'Recup. esperada', llave: 'recuperacion_esperada', unidad: '[%]' },
            { clases: 'min', tipo: 'num', nombre: 'NO3', llave: 'no3', unidad: '' },
            { clases: 'max', tipo: 'num', nombre: 'H consumido en aglomerado', llave: 'hconsumido_en_aglomerado', unidad: '[t]' },
            { clases: 'max', tipo: 'num', nombre: 'Agua consumido en aglomerado', llave: 'agua_consumido_en_aglomerado', unidad: '[t]' },
            { clases: 'max', tipo: 'num', nombre: 'Rlix alcanzado', llave: 'rlix_alcanzado', unidad: '[m3/tms]' },
            { clases: 'max', tipo: 'num', nombre: 'Rlix esperado', llave: 'rlix_estimado', unidad: '[m3/tms]' },
            { clases: 'max', tipo: 'num', nombre: 'Recup. alcanzada hrs riego', llave: 'recuperacion_alcanzada_h_riego', unidad: '[%]' },
            { clases: 'max', tipo: 'num', nombre: 'Recup. estimada hrs riego', llave: 'recuperacion_estimada_h_riego', unidad: '[%]' },
            { clases: 'max', tipo: 'num', nombre: 'Recup. alcanzada Rlix', llave: 'recuperacion_alcanzada_rlix', unidad: '[%]' },
            { clases: 'min', tipo: 'num', nombre: 'Total hrs riego', llave: 'horasTotalRiego', unidad: '[hrs]' },
        ];

        let lista = [];
        if( pilas.includes("0") ) {
            lista = datos;
        } else {
            lista = datos.filter( modulo => pilas.includes( modulo.pila_nombre ) );
        }

        return { llaves, lista: ordenarArray( 'id_modulo', llaves, lista, orden ) }

    }

    return {
        obtenerResumenActual,
        formatoNum,
        ordenarArray,
        descargarXLSX,
        obtenerCiclosPlantaAPI,
        obtenerHistoricoAPI,
        filtrarDatos
    }
}