
import digitalizacionAPI from "../../API/digitalizacionAPI";

export const guardarCosecha = () => {
    const obtenerListaVariables = ( datos ) => {
        // Obtener nombres tablas SX
        let variables = [];
        const tablas = Object.keys( datos );

        // Obtener variables de pestaña
        tablas.forEach(nombre => {
            const filas = datos[ nombre ].filas;
            
            filas.forEach(fila => {
                // Obtener variables en fila
                const lista = obtenerVariablesFila( fila );

                // Guardar de existir
                if( lista.length > 0 ) {
                    variables = [ ...variables, ...lista ];
                }
            });
        });

        return variables;
    }

    const obtenerVariablesFila = ( fila ) => {
        let lista = [];

        fila.forEach(celda => {
            if( celda.input ) {
                const { llave, valor, turno, original } = celda;
                lista = [ ...lista, {
                    llave,
                    valor,
                    turno,
                    original
                }];
            }
        });

        return lista;
    }

    const generarJSONTurnos = ( variables ) => {
        let cambio_a = false;
        let cambio_b = false;
        let json_turno_a = {};
        let json_turno_b = {};

        variables.forEach(({ llave, valor, turno, original }) => {
            if( turno == 'a' ) {
                json_turno_a = {
                    ...json_turno_a,
                    [ llave ]: valor
                };

                if( valor != original ) cambio_a = true;
                
            } else {
                json_turno_b = {
                    ...json_turno_b,
                    [ llave ]: valor
                };

                if( valor != original ) cambio_b = true;
            }

            
        });

        return {
            json_turno_a,
            json_turno_b,
            cambio_a,
            cambio_b
        }
    }

    const generarJSON = ( variables ) => {
        let cambio = false;
        let json = {};

        variables.forEach(({ llave, valor, original }) => {
            json = {
                ...json,
                [ llave ]: valor
            };

            if( valor != original ) cambio = true;
        });

        return {
            json,
            cambio
        }
    }
    
    const guardarEWSX = async (ewsx, tokensesion) => {
        const tablas = Object.keys(ewsx);
        let cambios = [];
        let data_json = {};
    
        tablas.forEach(tabla => {
            const validacion = validarCambios(ewsx[tabla]);
            if (validacion.length > 0) cambios = [...cambios, validacion];
        })
    
        cambios.forEach(data => {
            data.forEach(val => {
                const valor = val.valor;
                let nombre = val.variable;
                switch (nombre) {
                    case "Producción planta osmosis (m3/día)":
                        nombre = "prod_osmosis";
                        break;
                    case "Sector norte":
                        nombre = "ext_sector_norte";
                        break;
                    case "Sector sur":
                        nombre = "ext_sector_sur";
                        break;
                }
                data_json[nombre] = valor;
            })
        })

        const conf = {
            headers: { "x-token": tokensesion, 'Content-Type': 'application/json'  }
        }
        
        // console.log(conf);
    
        // const result = await digitalizacionAPI.post('/reportes/cosecha/inputs/ew-sx', data_json, conf);
        // console.log(result);
    
    }
    
    const guardarLixiviacion = async (datos, tokensesion) => {
        // Obtener tablas
        const tablas = Object.keys(datos);
        let cambios = [];
    
        tablas.forEach(tabla => {
            const validacion = validarCambios(datos[tabla]);
            if (validacion.length > 0) cambios = [...cambios, ...validacion];
        });
    
        // console.log(cambios);
    
        let datos_json = [];
        cambios.forEach(({
            tabla,
            valor,
            variable,
            pos
        }) => {
            const variables = obtenerNombreVariableLix(pos, variable, tabla, valor);
            datos_json = [...datos_json, variables];
        });
    
        let json_ta = {},
            json_tb = {};
    
        let json_a = '{';
        let json_b = '{';
        let existe_a = false;
        let existe_b = false;
        datos_json.forEach(({
            nombre,
            turno,
            valor
        }) => {
            if (turno == 'a') {
                existe_a = true;
                json_a += `"${nombre}": ${valor},`;
                json_ta[nombre] = Number(valor);
            } else {
                existe_b = true;
                json_b += `"${nombre}": ${valor},`;
                json_tb[nombre] = Number(valor);
            }
        });
    
        json_a += '}';
        json_b += '}';
    
        json_a = json_a.replace(',}', '}');
        json_b = json_b.replace(',}', '}');
    
        const conf = {
            headers: { "x-token": tokensesion, 'Content-Type': 'application/json'  }
        }
        
        if (existe_a) {

            // const res = await digitalizacionAPI.post('/reportes/cosecha/inputs/lix-ta', json_ta, conf);
            // console.log(res);
        }
    
        if (existe_b) {
            // const res = await digitalizacionAPI.post('/reportes/cosecha/inputs/lix-tb', json_tb, conf);
            // console.log(res);
        }
    
    }   
    
    const validarCambios = (tabla) => {
        const { filas } = tabla;
        let valores = [];

        filas.forEach(celdas => {
            const variable = celdas[0].valor;
            let pos = 0;
            celdas.forEach(({ valor, original, input, tabla }) => {
                pos += 1;
                if (input == true && valor != original) {
                    valores = [...valores, {
                        tabla,
                        valor,
                        variable,
                        pos
                    }]
                }
            });
        });
        return valores;
    }
    
    const obtenerNombreVariableSX = (pos, variable, tabla, valor) => {
        switch (tabla) {
            case 'tabla_turnos':
                switch (variable) {
                    case 'OTROS DESC EP':
                        return {
                            nombre: pos == 2 ? 'desc_ep_19' : 'desc_ep_07',
                                turno: pos == 2 ? 'a' : 'b',
                                valor
                        }
                }
                break;
    
            case 'tabla_arrastre':
                switch (variable) {
                    case 'E3A':
                        return {
                            nombre: pos == 2 ? 'ao_e3a_10' : 'ao_e3a_22',
                                turno: pos == 2 ? 'a' : 'b',
                                valor
                        }
    
                    case 'E3B':
                        return {
                            nombre: pos == 2 ? 'ao_e3b_10' : 'ao_e3b_22',
                                turno: pos == 2 ? 'a' : 'b',
                                valor
                        }
    
                }
                break;
    
            case 'tabla_flujos':
                switch (variable) {
                    case 'Desc. RAL a refino':
                        if (pos == 2 || pos == 4) { // turno a
                            return {
                                nombre: pos == 2 ? 'desc_ral_refino_ta_19' : 'desc_ral_refino_tb_19',
                                turno: 'a',
                                valor
                            }
                        } else { // turno b
                            return {
                                nombre: pos == 5 ? 'desc_ral_refino_ta_07' : 'desc_ral_refino_tb_07',
                                turno: 'b',
                                valor
                            }
                        }

                    case 'Desc. RAL a TK-102':
                        if (pos == 2 || pos == 4) { // turno a
                            return {
                                nombre: pos == 2 ? 'desc_ral_tk102_ta_19' : 'desc_ral_tk102_tb_19',
                                turno: 'a',
                                valor
                            }
                        } else { // turno b
                            return {
                                nombre: pos == 5 ? 'desc_ral_tk102_ta_07' : 'desc_ral_tk102_tb_07',
                                turno: 'b',
                                valor
                            }
                        }
    
                }
        }
    }
    
    const obtenerNombreVariableLix = (pos, variable, tabla, valor) => {
        switch (tabla) {
            case 'tabla_lix':
                switch (variable) {
                    case 'MOD HUMECTACIÓN':
                        return {
                            nombre: pos == 2 ? "mod_humectacion_10" : "mod_humectacion_22",
                                turno: pos == 2 ? 'a' : 'b',
                                valor
                        }

                    case "MODULOS EN RIEGO (din)":
                        return {
                            nombre: pos == 2 ? "modulos_en_riego_10" : "modulos_en_riego_22",
                                turno: pos == 2 ? 'a' : 'b',
                                valor
                        }

                    case "DRENAJE TOTAL DIN+MIR":
                        return {
                            nombre: pos == 2 ? "drenaje_total_10" : "drenaje_total_22",
                                turno: pos == 2 ? 'a' : 'b',
                                valor
                        }

                    case "MOD. DRENAJE":
                        return {
                            nombre: pos == 2 ? "modulos_drenaje_10" : "modulos_drenaje_22",
                                turno: pos == 2 ? 'a' : 'b',
                                valor
                        }
                }
                break;
    
            case 'tabla_agua_fresca':
                switch (variable) {
                    case 'PISCINA 1':
                        return {
                            nombre: pos == 2 ? "piscina_1_10" : "piscina_1_22",
                                turno: pos == 2 ? 'a' : 'b',
                                valor
                        }
    
                        case 'PISCINA 2':
                            return {
                                nombre: pos == 2 ? "piscina_2_10" : "piscina_2_22",
                                    turno: pos == 2 ? 'a' : 'b',
                                    valor
                            }
    
                }
                break;
        }
    }

    return {
        obtenerListaVariables,
        generarJSONTurnos,
        generarJSON,
        guardarEWSX,
        guardarLixiviacion
    }
    
}
