import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

export const Grafico = (props) => {
    return (   
        props.tipo === 'doble' 
        ?
          <ReactApexChart 
            height={ 300 }
            type='bar'
            options={{ 
              chart: {
                height: 350,
                fontFamily: 'Heebo, sans-serif'
              },
              title: {
                text: props.nombre
              },
              dataLabels: {
                enabled: false,
                enabledOnSeries: [0]
              },
              colors: ['#16549e'],
              labels: props.labels,
              annotations: {
                yaxis: [
                  {
                    y: 60,
                    borderColor: '#FA7349',
                    fillColor: '#FA7349',
                    borderWidth: 2,
                    strokeDashArray: 0,
                    label: {
                      borderWidth: 3,
                      borderColor: '#FA7349',
                      style: {
                        color: '#fff',
                        background: '#FA7349',
                        
                      },
                      text: 'Días objetivo'
                    }
                  }
                ]
              }
            }}

            series={[
              {
                name: props.series[0],
                type: 'column',
                data: props.valores_barra
              }
            ]}
          />
        :
          props.tipo === 'nuevo'
          ?
          <ReactApexChart 
            height={ 300 }
            type="bar" 
            options={ 
              {
                chart: {
                  height: 350,
                  type: 'bar',
                  fontFamily: 'Heebo, sans-serif'
                },
                title: {
                  text: props.nombre
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                  }
                },
                colors: ['#1F858B'],
                dataLabels: {
                  enabled: false,
                  enabledOnSeries: [0]
                },
                legend: {
                  show: true,
                  showForSingleSeries: true,
                  customLegendItems: ['Rlix real', 'Rlix esperado',  'Rlix objetivo'],
                  markers: {
                    fillColors: ['#1F858B', '#ffc33c', '#FA7349']
                  }
                },
                annotations: {
                  yaxis: [
                    {
                      y: 1.7,
                      borderColor: '#FA7349',
                      fillColor: '#FA7349',
                      borderWidth: 2,
                      strokeDashArray: 0,
                      label: {
                        borderWidth: 3,
                        borderColor: '#FA7349',
                        style: {
                          color: '#fff',
                          background: '#FA7349',
                          
                        },
                        text: props.rlix_objetivo
                      }
                    }
                  ]
                }
            }}

            series={ props.series }
          />
          : props.tipo === 'nuevo-este'
          ? 
            <ReactApexChart 
              height={ 300 }
              type="bar" 
              options={ 
                {
                  chart: {
                    height: 350,
                    type: 'bar',
                    fontFamily: 'Heebo, sans-serif',
                    zoom: {
                      enabled: true
                    }
                  },
                  title: {
                    text: props.nombre
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                    }
                  },
                  colors: ['#1F858B'],
                  dataLabels: {
                    enabled: false,
                    enabledOnSeries: [0]
                  },
                  legend: {
                    show: true,
                    showForSingleSeries: true,
                    customLegendItems: ['Rlix real', 'Rlix estimado a la fecha',  'Rlix objetivo'],
                    markers: {
                      fillColors: ['#1F858B', '#ffc33c', '#FA7349']
                    }
                  },
                  annotations: {
                    yaxis: [
                      {
                        y: 1.7,
                        borderColor: '#FA7349',
                        fillColor: '#FA7349',
                        borderWidth: 2,
                        strokeDashArray: 0,
                        label: {
                          borderWidth: 3,
                          borderColor: '#FA7349',
                          style: {
                            color: '#fff',
                            background: '#FA7349',
                            
                          },
                          text: '1.7'
                        }
                      }
                    ]
                  }
                }
              }

              series={ props.series }
            />
          : 
          <ReactApexChart 
            height={ 300 }
            type='bar'
            options={ {
                chart: {
                height: 350,
                type: 'line',
                fontFamily: 'Heebo, sans-serif'
              },
              title: {
                text: props.nombre
              },
              stroke: {
                  width: [0,4]
              },
              dataLabels: {
                enabled: false,
                enabledOnSeries: [0]
              },
              colors: ['#AE7A99'],
              labels: props.labels
            }}

            series={[
              {
                name: props.serie,
                type: 'column',
                data: props.valores_barra
              }
            ]}
          />
    )
}
