// API
import digitalizacionAPI from "../../API/digitalizacionAPI";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setRazonLixiviacion, limpiarRazonLixiviacion } from "../../store/digitalizacion/RazonLixiviacionSlice";
import { cerrarSesion } from "../../store/autentificacion/autentificacionSlice";

export const useRazonLixiviacion = () => {
    const dispatch = useDispatch();
    const XLSX = require("xlsx-js-style");

    // Token
    const { token } = useSelector((state) => state.autentificacion);
    const conf_api = { headers: { 'x-token': token }};

    // Planta es un ID (1: met, 2: oxe)
    const obtenerRlixAPI = async(planta, inicio) => {
        let datos_rlix = [];

        try {
            await digitalizacionAPI.get(`comun/rlix/${planta}`, conf_api).then(({ data:datos }) => {
                if(planta == 1) {
                    const datos_ordenados = datos.slice().sort(ordenarArray('Modulo'));
                    const ciclos = agruparCiclos(datos_ordenados);
                    datos_rlix = ciclos;
                    if(inicio != true) {
                        dispatch( setRazonLixiviacion({ciclos}) )
                    }
                } else {
                    console.log( datos );
                    datos_rlix = agruparOXE(datos);
                    // console.log(datos_rlix);
                    if(inicio != true) {
                        dispatch( setRazonLixiviacion({ datos: datos_rlix }) )
                    }
                }
            });
    
            
        } catch( err ) {
            console.log(err);
            if( err.response.data == 'Token inválido | TokenExpiredError: jwt expired' ) {
                localStorage.setItem("err-token", true);
                dispatch( cerrarSesion() );
            } else {
                console.log( err.response.data );
                return false;
            }
        }

        return datos_rlix;
    }

    const obtenerTodoRlixAPI = async(planta) => {
        try {
            let datos_rlix = [];
    
            await digitalizacionAPI.get(`comun/rlix/${planta}`, conf_api).then(({ data:datos }) => {
                if(planta == 2) {
                    // console.log(datos);
                    const { datos:data } = agruparOXE(datos);

                    console.log( datos );
                    datos_rlix = data;
                } else {
                    datos_rlix = datos;
                }
            })
    
            return datos_rlix;
        } catch( err ) {
            console.log(err);
            if( err.response.data == 'Token inválido | TokenExpiredError: jwt expired' ) {
                localStorage.setItem("err-token", true);
                dispatch( cerrarSesion() );
            } else {
                console.log( err.response.data );
                return false;
            }
        }
    }

    const agruparOXE = (data) => {
        const { Ciclos } = data;

        let datos = [];
        Ciclos.forEach(({ registros }) => {
            registros.forEach(registro => {
                if(registro.Modulo != '00') {
                    datos = [...datos, registro];
                }
            });
        });

        datos = datos.sort(ordenarArray('Nomenclatura'));;
        return {
            ...data,
            datos
        }
    }

    function ordenarArray(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const agruparCiclos = (datos) => {
        /**
         * DATOS 
         *      Caudal_Promedio: int
         *      Ciclo: string
         *      DiasDisponibles_RiegoEfectivo: float
         *      EstadiCiclo: string
         *      Fecha_Inicio_Ciclo: date
         *      Fecha_Termino_Riego: date
         *      Modulo: string
         *      Nomenclatura: string
         *      ProcentajeCumplomiento_RiegoEfectivo: float
         *      RazonRiego_Efectiva: float
         *      RazonRiego_Segun_DiasDisponibles
         *      TMS: float
         *      Total_Horas: float
         *      Volumen_Total: float
         *      id_modulo: int
         */

        let datos_agrupados = [];
        let grupo = [];
        let ciclo_actual = datos[0].Ciclo;
        let ciclos = [ciclo_actual];
        const fecha_actual = obtenerFechaActual();
        let lista_modulos = [];
        let lista_datos = [];

        datos.forEach(modulo => {
            // console.log(modulo.Nomenclatura);
            if(modulo.Nomenclatura != null && modulo.Modulo != "00" && !lista_modulos.includes(modulo.Modulo) ) {
                lista_modulos.push(modulo.Modulo);

                const { Ciclo, Total_Horas, Caudal_Promedio } = modulo;
                const m3_promedio = Total_Horas * Caudal_Promedio;
                
                if(ciclo_actual != Ciclo) {
                    datos_agrupados = [...datos_agrupados, {
                        ciclo: ciclo_actual,
                        registros: grupo
                    }]
                    
                    ciclos = [...ciclos, Ciclo];
                    ciclo_actual = Ciclo;
                    grupo = [];
                } else {
                    grupo = [...grupo, {
                        ...modulo,
                        m3_promedio,
                        fecha_actual
                    }]
                }

                lista_datos = [...lista_datos, modulo ];
            }
        });

        datos_agrupados = [...datos_agrupados, {
            ciclo: ciclo_actual,
            registros: grupo
        }];

        // console.log(datos);
        return {
            ciclos,
            datos_agrupados,
            datos: lista_datos
        };
    }

    const obtenerFechaActual = () => {
        const date = new Date();
        const dia = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const mes = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
        const anio = date.getFullYear();

        return `${anio}-${mes}-${dia}`;
    }

    const descargarRlix = (planta, ciclo, modulos, cabecera ) => {
        const estilo_cabecera = {
            font: {
                sz: 12,
                bold: true,
                color: {
                    rgb: "ffffff"
                }
            },
            alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: '333333'
                }
            },
            border: {
                bottom: {
                    style: "thin",
                    color: {
                        rgb: "d8d8d8"
                    }
                },
                top: {
                    style: "thin",
                    color: {
                        rgb: "d8d8d8"
                    }
                },
                left: {
                    style: "thin",
                    color: {
                        rgb: "d8d8d8"
                    }
                },
                right: {
                    style: "thin",
                    color: {
                        rgb: "d8d8d8"
                    }
                }
            }
        };
        const estilo_cuerpo = {
            font: {
                bold: false,
                sz: 11,
                color: {
                    rgb: '333333' 
                }
            },
            alignment: {
                vertical: "center",
                horizontal: "center"
            },
            fill: {
                fgColor: {
                    rgb: 'ffffff'
                }
            },
            border: {
                bottom: {
                    style: "thin",
                    color: {
                        rgb: "d8d8d8"
                    }
                },
                top: {
                    style: "thin",
                    color: {
                        rgb: "d8d8d8"
                    }
                },
                left: {
                    style: "thin",
                    color: {
                        rgb: "d8d8d8"
                    }
                },
                right: {
                    style: "thin",
                    color: {
                        rgb: "d8d8d8"
                    }
                }
            }
        };

        const tabla = document.getElementById("tabla-rlix");

        let opts = [ { wch: 3 } ];
        let fila = [{}];
        let hoja =  XLSX.utils.aoa_to_sheet([ fila ]);
        let origen = 1;

        cabecera.forEach(nombre => {
            opts = [...opts, { wch: 20 }]
            fila = [...fila, { v: nombre, t: 's', s: estilo_cabecera }];
        });

        XLSX.utils.sheet_add_aoa(hoja, [ fila ], { origin: origen });
        origen += 1;

        
        let alto_filas = [{ 'hpt': 16 }, { 'hpt': 35 }];

        modulos.forEach(modulo => {
            alto_filas = [...alto_filas, { hpt: 18 }];

            if( planta == 'oxe' ) {
                const { Nomenclatura,  DiasRiego_Efectivo, TMS, 
                Volumen_Total, rlix_real, Rlix_Estimado, EstadoCiclo } = modulo;
                fila = [
                    {},
                    { v: Nomenclatura, t: 's', s: estilo_cuerpo },
                    { v: DiasRiego_Efectivo, t: 'n', s: estilo_cuerpo },
                    { v: TMS, t: 'n', s: estilo_cuerpo },
                    { v: Volumen_Total, t: 'n', s: estilo_cuerpo },
                    { v: rlix_real, t: 'n', s: estilo_cuerpo },
                    { v: Rlix_Estimado, t: 'n', s: estilo_cuerpo },
                    { v: EstadoCiclo, t: 's', s: estilo_cuerpo },
                ];

            } else {
                const { Modulo,  Total_Horas, TMS, DiasRiego_Efectivo,
                Volumen_Total, RazonRiego_Efectiva, Rlix_Estimado, EstadoCiclo } = modulo;

                fila = [
                    {},
                    { v: Modulo, t: 's', s: estilo_cuerpo },
                    { v: TMS, t: 'n', s: estilo_cuerpo },
                    { v: Total_Horas, t: 'n', n: estilo_cuerpo },
                    { v: Volumen_Total, t: 'n', n: estilo_cuerpo },
                    { v: DiasRiego_Efectivo, t: 'n', s: estilo_cuerpo },
                    { v: RazonRiego_Efectiva, t: 'n', s: estilo_cuerpo },
                    { v: Rlix_Estimado, t: 'n', s: estilo_cuerpo },
                    { v: EstadoCiclo, t: 's', s: estilo_cuerpo },
                ];
            }

            
            XLSX.utils.sheet_add_aoa(hoja, [ fila ], { origin: origen });
            origen += 1;
        });


        hoja['!cols'] = opts;
        hoja['!rows'] = alto_filas;

        
        const archivo = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(archivo, hoja, `Razón lixiviación ${planta}`);
        XLSX.writeFile(archivo, `Razón_de_lixiviación_${planta.toUpperCase()}.xlsx`, {  editable: true });
    }

    const cambiarTabRlix = (tab_nueva, btn_nuevo, tab_origen, btn_origen) => {
        document.getElementById(tab_nueva).classList.add("activo");
        document.getElementById(btn_nuevo).classList.add("activo");

        document.getElementById(tab_origen).classList.remove("activo");
        document.getElementById(btn_origen).classList.remove("activo");
    }

    return {
        obtenerRlixAPI,
        descargarRlix,
        obtenerTodoRlixAPI,
        cambiarTabRlix
    }
}