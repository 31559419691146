import { useRazonLixiviacion } from "../../../hooks/useRazonLixiviacion"

export const FilaSuperior = (props) => {
  const { descargarRlix } =  useRazonLixiviacion();

  return (
    <div className="fila-superior">
        <h3 className="titulo-pagina">
            RAZÓN DE LIXIVIACIÓN { props.planta.toUpperCase() }
        </h3>

        <div className="cont-btns">
            {/* Exportar datos de tablas */}
            <button 
              className="btn waves-effect waves-light deep-orange lighten-1 btn-exportar" 
              onClick={ () => { descargarRlix(props.planta, props.ciclo, props.modulos, props.cabecera ) } }
            >
                <i className="material-icons left">download</i>
                DESCARGAR
            </button>

        </div>
    </div>
  )
}
