// React
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useAuthStore } from "../../autentificacion/hooks/useAutentificacion";
import { useNavigate } from "react-router";

// Materialize
import M from 'materialize-css';

// Estilos
import './styles/SideBar.scss';
import { clear } from 'localforage';

const logo_centinela = require('../../assets/img/sesion_centinela.png');

export const Sidebar = () => {
    const { cerrarSesionStore } = useAuthStore();
    const { nombre, rol, token } = useSelector((state) => state.autentificacion)

    const [posSideBar, setPosSideBar] = useState(false);
    const navigate = useNavigate();
    let { pathname } = useLocation();
    const [Vista, setVista] = useState('')

    useEffect(() => {       
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems);
    }, [])

    useEffect(() => {       
        switch (true) {
            case pathname.includes('/inicio') === true :
                setVista('inicio');
                break;

            case pathname.includes('/met/control-riego') === true :
                setVista('met-controlriego');
                break;

            case pathname.includes('/oxe/control-riego') === true :
                setVista('oxe-controlriego');
                break;

            case pathname.includes('/met/razon-lixiviacion') === true :
                setVista('met-rlix');
                break;

            case pathname.includes('/oxe/razon-lixiviacion') === true :
                setVista('oxe-rlix');
                break;

            case pathname.includes('/met/resumen-modulos') === true :
                setVista('met-resumen');
                break;

            case pathname.includes('/oxe/resumen-modulos') === true :
                setVista('oxe-resumen');
                break;
        }
    }, [pathname])

    const cerrarSesion = () => {
        cerrarSesionStore();
        navigate('/autentiticar')
    }

    return (
        <div className="sidebar" style={ posSideBar ? { left: '0' } : { left: '-240px' } }>
            {/* Botón abrir-cerrar */}
            <div className="contenedor-btns-sidebar">
                <button
                    className={ posSideBar ? 'btn-cerrar' : 'btn-abrir' }
                    onClick={ () => { setPosSideBar(!posSideBar); }}
                >
                    {
                        posSideBar 
                        ? <i className="material-icons">close</i>
                        : <i className="material-icons">menu</i>
                    }
                </button>
            </div>

            {/* Usuario */}
            <div className='cont-sesion'>
                <div className="cont-icon-centinela">
                    <img src={ logo_centinela } alt="logo_cliente" />
                </div>
                <p className='nombre'>{ nombre.replace(/_/g, " ") }</p>
                <p className='rol'>{ rol }</p>
            </div>
            
            <div className="collection">
                <a href="/inicio" className={ Vista === 'inicio' ? 'collection-item active' : 'collection-item' }>
                    <i className="material-icons left" style={ posSideBar ? { marginLeft: '0' } : { marginLeft: '233px' }}>home</i>
                    <label style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>Inicio</label>
                </a>

                <a 
                    // href={ `https://dev.smartpls.cl/integracion-smartpls/index.html?token=${token}` } 
                    href={ `https://centinela.smartpls.cl/integracion-smartpls/index.html?token=${token}` } 
                    className='collection-item'
                >
                    <i className="material-icons left" style={ posSideBar ? { marginLeft: '0' } : { marginLeft: '233px' }}>arrow_back</i>
                    <label style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>Volver a SmartPLS</label>
                </a>

                <ul className="collapsible">
                    {/* Histórico */}
                    {/* <li>
                        <div className="collapsible-header" onClick={() => { if(!posSideBar) setPosSideBar(!posSideBar) } }>
                            <a href="#">
                                <i className="material-icons left" style={ posSideBar ? { marginLeft: '0' } : { marginLeft: '233px' }}>content_paste</i>
                                <label style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>Histórico</label>
                                <i className="material-icons right" style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>expand_more</i>
                            </a>
                            
                        </div>

                        <div className="collapsible-body">
                            <div className="collection">
                                <a  href="#" className="collection-item">Planta MET</a>
                                <a  href="#" className="collection-item">Planta OXE</a>
                                <a  href="#" className="collection-item">Planta ROM</a>
                            </div>
                        </div>
                    </li> */}

                    {/* Inicio */}
                    

                    {/*  Planta MET */}
                    <li>
                        <div className={ Vista.includes('met') ? "collapsible-header active" : "collapsible-header" } onClick={() => { if(!posSideBar) setPosSideBar(!posSideBar) } }>
                            <a href="#">
                                <i className="material-icons left" style={ posSideBar ? { marginLeft: '0' } : { marginLeft: '233px' }}>corporate_fare</i>
                                <label style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>Planta MET</label>
                                <i className="material-icons right" style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>expand_more</i>
                            </a>
                        </div>

                        <div className="collapsible-body">
                            <div className="collection">
                                <a  href="/met/resumen-modulos" className={ Vista === 'met-resumen' ? 'collection-item sub active' : 'collection-item' }>Resumen módulos</a>
                                <a  href="/met/control-riego" className={ Vista === 'met-controlriego' ? 'collection-item sub active' : 'collection-item' }>Control de riego</a>
                                {/* <a  href="/met/rampa-humectacion" className="collection-item">Rampa humectación</a> */}
                                <a  href="/met/razon-lixiviacion" className={ Vista === 'met-rlix' ? 'collection-item sub active' : 'collection-item' }>Razón lixiviación</a> 
                            </div>
                        </div>
                    </li>

                    {/* Planta OXE */}
                    <li>
                        <div className={ Vista.includes('oxe') ? "collapsible-header active" : "collapsible-header" } onClick={() => { if(!posSideBar) setPosSideBar(!posSideBar) } }>
                            <a href="#">
                                <i className="material-icons left" style={ posSideBar ? { marginLeft: '0' } : { marginLeft: '233px' }}>corporate_fare</i>
                                <label style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>Planta OXE</label>
                                <i className="material-icons right" style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>expand_more</i>
                            </a>
                            
                        </div>

                        <div className="collapsible-body">
                            <div className="collection">
                                <a  href="/oxe/resumen-modulos" className={ Vista === 'oxe-resumen' ? 'collection-item sub active' : 'collection-item' }>Resumen módulos</a>
                                <a  href="/oxe/control-riego" className={ Vista === 'oxe-controlriego' ? 'collection-item sub active' : 'collection-item' }>Control de riego</a>
                                {/* <a  href="/oxe/rampa-humectacion" className="collection-item">Rampa humectación</a> */}
                                <a  href="/oxe/razon-lixiviacion" className={ Vista === 'oxe-rlix' ? 'collection-item sub active' : 'collection-item' }>Razón lixiviación</a>
                                {/* <a  href="#" className="collection-item">Consumo de ácido</a>
                                <a  href="#" className="collection-item">Consumo agua de mar</a> */}
                            </div>
                        </div>
                    </li>

                    {/* ROM */}
                    <li>
                        <div className="collapsible-header" onClick={() => { if(!posSideBar) setPosSideBar(!posSideBar) } }>
                            <a href="#">
                                <i className="material-icons left" style={ posSideBar ? { marginLeft: '0' } : { marginLeft: '233px' }}>content_paste</i>
                                <label style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>ROM</label>
                                <i className="material-icons right" style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>expand_more</i>
                            </a>
                            
                        </div>

                        <div className="collapsible-body">
                            <div className="collection">
                                <a  href="/rom/resumen-produccion" className="collection-item">Resumen producción ROM</a>
                            </div>
                        </div>
                    </li>

                    {/* Reportes */}
                    <li>
                        <div className="collapsible-header" onClick={() => { if(!posSideBar) setPosSideBar(!posSideBar) } }>
                            <a href="#">
                                <i className="material-icons left" style={ posSideBar ? { marginLeft: '0' } : { marginLeft: '233px' }}>bar_chart</i>
                                <label style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>Reportes</label>
                                <i className="material-icons right" style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>expand_more</i>
                            </a>
                            
                        </div>
                        <div className="collapsible-body">
                            <div className="collection">
                                <a  href="/reportes/analisis-quimico" className="collection-item">Análisis químico</a>
                                {/*<a  href="#" className="collection-item">Balances flujos MET-OXE</a>
                                <a  href="#" className="collection-item">Informe diario (A Y B)</a>
                                <a  href="#" className="collection-item">Niveles y flujo</a> */}
                                <a  href="/reportes/cosecha" className="collection-item">Cosecha</a> 
                                {/*<a  href="/reportes/reporte-preliminar" className='collection-item'>Reporte Preliminar</a>*/}
                            </div>
                        </div>
                    </li> 

                    {/* Simulación */}
                    {/* <li>
                        <div className="collapsible-header" onClick={() => { if(!posSideBar) setPosSideBar(!posSideBar) } }>
                            <a href="#">
                                <i className="material-icons left" style={ posSideBar ? { marginLeft: '0' } : { marginLeft: '233px' }}>auto_graph</i>
                                <label style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>Simulación</label>
                                <i className="material-icons right" style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>expand_more</i>
                            </a>
                            
                        </div>
                        <div className="collapsible-body">
                            <div className="collection">
                                <a  href="/simulacion/sx" className="collection-item">Simulación a SX</a>
                            </div>
                        </div>
                    </li>  */}

                    {/* Históricos */}
                    <li>
                        <div className="collapsible-header" onClick={() => { if(!posSideBar) setPosSideBar(!posSideBar) } }>
                            <a href="#">
                                <i className="material-icons left" style={ posSideBar ? { marginLeft: '0' } : { marginLeft: '233px' }}>history</i>
                                <label style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>Históricos</label>
                                <i className="material-icons right" style={ posSideBar ? { display: 'inline'} : { display: 'none' } }>expand_more</i>
                            </a>
                            
                        </div>
                        <div className="collapsible-body">
                            <div className="collection">
                                <a  href="/historicos/modulos" className="collection-item">Módulos</a>
                            </div>
                        </div>
                    </li> 
                </ul>
            </div>

            
            
            <div className="cont-btn-cerrar-sesion">
                <button 
                    className='btn waves-effect waves-light'
                    style={ posSideBar ? { width: '100%' } : { width: '50%' } }
                    onClick={ cerrarSesion }
                >
                    <i className="material-icons left">logout</i>
                    CERRAR SESIÓN
                </button>
            </div>
        </div>
    )
}

const menu = [
    {
        id: 'item-horas-riego',
        icono: 'water_drop',
        texto: 'Horas de riego',
        to: '/'
    }
]
