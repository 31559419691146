import { Routes, Route, Navigate } from "react-router-dom"
import { useLocation } from 'react-router-dom'




import { InicioPage } from "../modules/inicio/InicioPage";
import { ControlRiegoPage } from "../modules";
import { RazonLixiviacionPage } from "../modules/razon-lixiviacion/RazonLixiviacionPage";
// import { RampaHumectaciónPage } from "../modules/rampa-humectacion/RampaHumectacionPage";
import { ResumenModulosPage } from "../modules/resumen-modulos/ResumenModulosPage";
import { CosechaPage } from "../modules/cosecha/CosechaPage";
import { HistoricoModulosPage } from "../modules/historico-modulos/HistoricoModulosPage";

import { ReportePreliminarPage } from "../modules/reporte-preliminar/ReportePreliminarPage";

import { Navbar } from "../components";
import { Sidebar } from "../components/Sidebar";


import { ResumenProduccionROMPage } from "../modules/resumen-rom/ResumenProduccionROMPage";
import { AnalisisQuimicoPage } from "../modules/analisis-quimico/AnalisisQuimicoPage";
import { SimulacionSXPage } from "../modules/simulacion-sx/SimulacionSXPage";

import '../../app.scss';
// import { useEffect } from "react";

export const DigitalizacionRoutes = () => {
  const { pathname } = useLocation();
  
  return (
    <>  
      {/* Componentes generales */}
      {
        pathname.includes('/integracion-smartpls')
        ? <></>
        : 
          <>
            <Navbar />
            <Sidebar />
          </>
      }
      
      {/* Rutas */}
      <Routes>
        <Route path="/*" element={ <Navigate to="/inicio" /> } />
        <Route path="/inicio" element={ <InicioPage /> } />
        <Route path="/:planta/control-riego/" element={ <ControlRiegoPage /> } />
        <Route path="/:planta/razon-lixiviacion/" element={ <RazonLixiviacionPage /> } />
        <Route path="/:planta/resumen-modulos" element={ <ResumenModulosPage/> } />
        {/* <Route path="/:planta/rampa-humectacion/" element={<RampaHumectaciónPage/>} /> */}
        <Route path="/:planta/resumen-produccion" element={ <ResumenProduccionROMPage/> } />
        <Route path="/reportes/cosecha" element={ <CosechaPage /> } />
        <Route path="/reportes/analisis-quimico" element={ <AnalisisQuimicoPage /> } /> 
        <Route path="/historicos/modulos" element={ <HistoricoModulosPage /> } /> 
        {/* <Route path="/simulacion/SX" element={ <SimulacionSXPage /> } />  */}
        { /* <Route path="/reportes/reporte-preliminar" element={ <ReportePreliminarPage /> } />  */ }
      </Routes>
    </>
  )
}

