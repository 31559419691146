
import { useEffect, useRef, useState } from 'react';
import { useHistoricoModulos } from '../../hooks/useHistoricoModulos';
import TablaResumen from './components/TablaResumen';

import './styles/contenido.scss';
import { MsjError } from '../../components/MsjError';
import { Loader } from '../../components/Loader';
import { useParams } from 'react-router-dom';
import { FiltrosResumen } from './components/FiltrosResumen';
import FiltrosHistorico from './components/FiltrosHistorico';

export const HistoricoModulosPage = () => {
  const { ordenarArray, obtenerResumenActual, formatoNum } = useHistoricoModulos();

  const [ DatosTabla, setDatosTabla ] = useState([]);
  const [ LoaderResumen, setLoaderResumen ] = useState( true );
  const [ LoaderHistorico, setLoaderHistorico ] = useState( false );
  const [ Interfaz, setInterfaz ] = useState( null );
  const [ TablaHistorico, setTablaHistorico ] = useState([]);
  const [ Cabecera, setCabecera ] = useState([]);
  const [ CabeceraHistorico, setCabeceraHistorico ] = useState([]);
  const [ Planta, setPlanta ] = useState( 2 );
  const [ Vista, setVista ] = useState('resumen');
  const [ Orden , setOrden ] = useState('Descendente');
  const [ Filtro , setFiltro ] = useState('id_modulo_operacion');

  const ref_resumen = useRef( null )
  const ref_buscar = useRef( null )
  const ref_tab_resumen = useRef( null )
  const ref_tab_buscar = useRef( null )

  useEffect(() => {
    obtenerDatos();
  }, []);

  useEffect(() => {
    obtenerDatos();
  }, [ Planta ]);


  useEffect(() => {
    if( DatosTabla.length != 0 ) {
      ordenarTabla();
    }
  }, [ Filtro, Orden ]);

  const ordenarTabla = () => {
    if( Vista == 'resumen' && DatosTabla.length != 0 ) {
      let nueva = ordenarArray( Filtro, Cabecera, DatosTabla, Orden );
      setDatosTabla( nueva );
    }
  }

  const obtenerDatos = async() => {
    try {
      setLoaderResumen( true );
      const { llaves, datos } = await obtenerResumenActual( Planta );
      // console.log(llaves);
      // console.log(datos);
      setDatosTabla( datos );
      setCabecera( llaves );
      setLoaderResumen( false );

    } catch ( er ) {
      setInterfaz( false );
    }
  }
  
  const cambiarVista = ( tipo ) => {
    switch( tipo ) {
      case 'resumen':
        setVista( 'resumen' );
        (ref_resumen.current).classList.add( 'activo' );
        (ref_buscar.current).classList.remove( 'activo' );

        (ref_tab_resumen.current).classList.add( 'activo' );
        (ref_tab_buscar.current).classList.remove( 'activo' );
        break;

      case 'buscar':
        setVista( 'buscar' );
        (ref_buscar.current).classList.add( 'activo' );
        (ref_resumen.current).classList.remove( 'activo' );

        (ref_tab_buscar.current).classList.add( 'activo' );
        (ref_tab_resumen.current).classList.remove( 'activo' );
        break;
    }
  }

  return (
    <div className="contenido">
      {

          DatosTabla.length == 0
          ? <Loader />
          : Interfaz == false
          ? 
            <MsjError />

          : 
            <>
              <div className="cont-tabs">
                <button 
                  className="waves-effect waves-light btn-tab-historico activo"
                  onClick={ () => { cambiarVista('resumen') }}
                  ref={ ref_resumen }
                >Resumen actual</button>
                <button 
                  className="waves-effect waves-light btn-tab-historico"
                  onClick={ () => { cambiarVista('buscar') }}
                  ref={ ref_buscar }
                >Obtener histórico</button>
              </div>

              <div className="contenido-tab">
                {
                  Vista == 'resumen'
                  ? <FiltrosResumen 
                      setPlanta={ setPlanta } 
                      datos={ DatosTabla } 
                      opciones={ Cabecera } 
                      orden={ Orden } 
                      setOrden={ setOrden } 
                      setFiltro={ setFiltro }
                    />
                  : <></>
                }

                <div className="tab-historico activo" ref={ ref_tab_resumen }>
                  {
                    LoaderResumen
                    ? <div className="cont-loader-cont"><span className="loader-cont tabla"></span></div>
                    : <TablaResumen vista={ Vista } datos={ DatosTabla } cabecera={ Cabecera } formatoNum={ formatoNum } />
                  }
                </div>

                <div className="tab-historico" ref={ ref_tab_buscar }>
                  <div className="cont-historico">
                    <FiltrosHistorico setLoaderHistorico={ setLoaderHistorico } setTablaHistorico={ setTablaHistorico }  setCabeceraHistorico={ setCabeceraHistorico }  TablaHistorico={ TablaHistorico } CabeceraHistorico={ CabeceraHistorico }   />
                      {
                        LoaderHistorico
                        ? <div className="cont-loader-cont"><span className="loader-cont tabla"></span></div>
                        :
                          <TablaResumen 
                            vista={ Vista }
                            datos={ TablaHistorico } 
                            cabecera={ CabeceraHistorico } 
                            formatoNum={ formatoNum } 
                          />
                      }

                  </div>
                </div>
              </div>
            </> 
              
      }
        
    </div>
  )
}
