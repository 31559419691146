"use client"
import M from "materialize-css";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useHistoricoModulos } from "../../../hooks/useHistoricoModulos";

const FiltrosHistorico = ({ setLoaderHistorico, CabeceraHistorico, TablaHistorico, setTablaHistorico, setCabeceraHistorico, setDatosDescargaHistorico }) => {
    const { descargarXLSX, obtenerCiclosPlantaAPI, obtenerHistoricoAPI, filtrarDatos, ordenarArray } = useHistoricoModulos();
    const sel_planta = useRef( null );
    const sel_ciclo = useRef( null );

    const [ Datos, setDatos ] = useState([])
    const [ Planta, setPlanta ] = useState("0");
    const [ Ciclos, setCiclos ] = useState([]);
    const [ Pilas, setPilas ] = useState([]);
    const [ Filtros , setFiltros ] = useState( [] );
    const [ Orden , setOrden ] = useState('Descendente');
    const [ Filtro, setFiltro ] = useState("id_modulo");
    const [ LoaderPilas, setLoaderPilas ] = useState( false );
    const select_col = useRef(null)

    const validarFiltros = () => {
        const planta = Planta;
        const lista_pilas = document.querySelectorAll("#select-pilas option") ? obtenerPilasSeleccionadas( document.querySelectorAll("#select-pilas option") ) : [];

        if( planta == 0 || lista_pilas.length == 0 ) {
            M.toast({html: `<i class="material-icons">error</i> Debe seleccionar una planta, un ciclo y al menos una pila`, classes: 'rounded red'});
        } else {
            // Filtrar lista
            setLoaderHistorico( true );

            const { llaves, lista } = filtrarDatos( Datos, lista_pilas );
            setFiltros( llaves );
            setTablaHistorico( lista );
            setCabeceraHistorico( llaves );

            setLoaderHistorico( false );
        }
    }

    const obtenerPilasSeleccionadas = ( opciones ) => {
        let lista = [];
        opciones.forEach( opcion => {
            if( opcion.selected ) lista = [ ...lista, opcion.value ];
        })
        return lista;
    }

    useEffect(() => {
      if( Planta == "0" ) {
        setCiclos([]);
        setPilas([]);
      } else {
        obtenerCiclos();
      }
    }, [ Planta ]);
    
    const obtenerPilasModulos = async() => {
        if( sel_ciclo.current.value == "0" ) {
            setPilas([]);
        } else {
            setLoaderPilas( true );
            const { pilas, data }= await obtenerHistoricoAPI( Planta, sel_ciclo.current.value, Orden );
            setDatos( data );
            setPilas( pilas );
            setLoaderPilas( false );
        }
    }

    const obtenerCiclos = async() => {
        // Obtener ciclo de planta
        const lista = await obtenerCiclosPlantaAPI( Planta );
        setCiclos( lista )
    }

    useEffect(() => {
        ordenarTabla();
    }, [ Filtro, Orden ]);

    const ordenarTabla = () => {
        if( TablaHistorico.length != 0 ) {
          let nueva = ordenarArray( Filtro, CabeceraHistorico, TablaHistorico, Orden );
          setTablaHistorico( nueva );
        }
    }
    
    
    return (
        <div className="filtros-historicos">
            {
                TablaHistorico.length == 0
                ?
                    <button 
                        disabled
                        className="waves-effect waves-light btn-dl btn light-blue darken-4"
                        onClick={ () => { descargarXLSX( TablaHistorico, CabeceraHistorico, 'Histórico módulos', ( (sel_planta.current).options[(sel_planta.current).selectedIndex].text ), sel_ciclo.current.value ) }}
                    >
                        <i className="material-icons left">download</i>
                        DESCARGAR
                    </button>

                :
                    <button 
                        className="waves-effect waves-light btn-dl btn light-blue darken-4"
                        onClick={ () => { descargarXLSX( TablaHistorico, CabeceraHistorico, 'Histórico módulos', ( (sel_planta.current).options[(sel_planta.current).selectedIndex].text ), sel_ciclo.current.value ) }}
                    >
                        <i className="material-icons left">download</i>
                        DESCARGAR
                    </button>

            }

            {/* Planta */}
            <div className="filtro">
                <label htmlFor="select-planta-historico">Planta</label>
                <select 
                    id="select-planta-historico" 
                    className="browser-default" 
                    ref={ sel_planta }
                    onChange={ () => { setPlanta( ( sel_planta.current ).value ) }}
                >
                    <option value="0">Seleccionar...</option>
                    <option value="2">OXE</option>
                    <option value="1">MET</option>
                </select>
            </div>

            <div className="filtro">
                <label>Ciclo(s)</label>
                    {
                        Ciclos.length == 0
                        ?
                            <div className="cont-checks scroll"> 
                                <p>Seleccione un planta para ver opciones</p>
                            </div>
                        :
                            <select 
                                id="select-ciclo-historico"
                                className="browser-default"
                                ref={ sel_ciclo }
                                onChange={ () => { obtenerPilasModulos() }}
                            >
                                <option value="0">Seleccionar...</option>
                                {
                                    Ciclos.map(( ciclo, index ) => (
                                        <option key={ index } value={ ciclo }>{ ciclo }</option>
                                    ))
                                }

                            </select>
                    }
                
            </div>

            <div className="filtro">
                <label>Pila(s)</label>
                {
                    LoaderPilas
                    ? <div className="cont-loader-cont"><span className="loader-cont filtro"></span></div>

                    : Pilas.length == 0
                        ? <div className="cont-checks"><p>Seleccione un ciclo para ver opciones</p></div>
                        : 
                            <select className="browser-default select-pilas" multiple id="select-pilas">
                                <option value="0">Todas</option>
                                {
                                    Pilas.map(( pila, index) => (
                                        <option key={ index } value={ pila }>{ pila }</option>
                                    ))
                                }
                            </select>                 
                }
                
            </div>

            <hr />

            <div className="filtro orden">
                <label htmlFor="select-cols">Ordenar por</label>
                {
                    Filtros.length == 0
                    ?   
                        <>
                            <select disabled className="browser-default" >
                                <option value="0">-</option>
                            </select>

                            <button className='btn-orden-historico waves-effect waves-light btn deep-orange lighten-2' disabled >
                                <i className="material-icons left">sync_alt</i>
                                { Orden }
                            </button>

                        </>

                    :
                        <>
                            <select 
                                id="select-cols" 
                                className="browser-default" 
                                ref={ select_col } 
                                onChange={ () => { setFiltro( select_col.current.value ) }}
                            >
                                {
                                    Filtros.map(({ nombre, llave }, index) => (
                                        <option value={ llave } key={ index }>{ nombre }</option>
                                    ))
                                }
                            </select>

                            <button className='btn-orden-historico  waves-effect waves-light btn deep-orange lighten-2' onClick={ () => { setOrden( Orden == 'Ascendente' ? 'Descendente' : 'Ascendente' ) }}>
                                <i className="material-icons left">sync_alt</i>
                                { Orden }
                            </button>
                        </>
                }
            </div>

            <button
                className="btn waves-effect waves-light blue-grey darken-3"
                onClick={ () => { validarFiltros() }}
            >Aplicar</button>
        </div>
    )
}

export default FiltrosHistorico