// Store
import { useAuthStore } from '../hooks/useAutentificacion';
import { useEffect, useState } from "react";

// Hooks
import { useForm } from "react-hook-form";

// Estilos SCSS
import M from 'materialize-css';

const logo_gif = require('../../assets/img/logos/smartpls_con_slogan.png')

export const CuadroLogin = () => {
  // variable State para controlar validación de correo
  const [valCorreo, setvalCorreo] = useState(false);
  const [estadoVal, setEstadoVal] = useState('no-validado');
  const [Interfaz, setInterfaz] = useState(false);

  // Hook para validación y gestión de formulario
  const { register, formState: { errors }, handleSubmit } = useForm({ mode: "onChange"});

  // Hook para autentificación
  const { autentificarAPI, validarCorreo, autentificarStore } = useAuthStore();

  const autentificarUsuario = async(data) => {
    // obtener nombre
    const input = document.getElementById("input-correo");
    const correo= input.value.trim();
    const { clave } = data;
    if(!valCorreo) {
      // console.log(correo);
    } else {
      const [ nombre, dominio ] = correo.split("@");
      const res = await autentificarAPI(data);

      // Autentificación correcta
      if(res.ok) {
        // console.log(res)
        const { correo, nombre, rol, token, uid } = res;
        autentificarStore({ correo, nombre, rol, token, uid, dominio });

      } else { // Error al autentificar
        M.toast({html: `<i class="material-icons">error</i> Credenciales incorrectas`, classes: 'rounded red'})
      }
    }
  }

  const verificarCorreo = async() => {
    // Obtener texto de input
    const input = document.getElementById("input-correo");
    const correo = input.value.trim();

    if(valCorreo) {
      setvalCorreo(false);
      setEstadoVal('no-validado')

    } else if(correo == null || correo == '') {
      M.toast({html: `<i class="material-icons">error</i> Debe ingresar un correo`, classes: 'rounded red'});

    } else {
      setEstadoVal('validando');
      M.Toast.dismissAll();
      setTimeout(async() => {
        const resultado = await validarCorreo(correo);
        
        // Si no existe arrojar error
        switch(resultado) {
          case 'Network Error':
            setEstadoVal('no-validado');
            M.toast({html: `<i class="material-icons">error</i> Ocurrió un problema al intentar conectar. Intente de nuevo mas tarde`, classes: 'rounded red'})
            break;

          case false:
            setEstadoVal('no-validado');
            M.toast({html: `<i class="material-icons">error</i> El usuario no se encuentra registrado`, classes: 'rounded red'})
            break;

          case true: 
            setEstadoVal('validado');
            setvalCorreo(true);
            break;
        }
      }, 2000)
    }

  }

  useEffect(() => {
    if( !Interfaz ) {
      setInterfaz(true);
      if( localStorage.getItem("err-token") === true ) {
        localStorage.removeItem("err-token");
        M.toast({html: `<i class="material-icons">error</i> Sesión caducada`, displayLength: 15000, classes: 'error'});
      }
    }
  }, [])
  

  return (
    <div className="card blue-grey">
        {/* Logo SmartPLS */}
        <img src={ logo_gif } alt="" />

        {/* Formulario */}
        <form onSubmit={ handleSubmit(autentificarUsuario) }>
            {/* Correo */}
            <div className="input-field input-outlined">
                {/* Ícono */}
                <i className="material-icons prefix">mail</i>

                {/* Input */}
                <input
                    disabled={ valCorreo ? true : false }
                    className="validate"
                    id="input-correo"
                    type="text"
                    { ...register("correo", {
                            required: "Campo obligatorio",
                    })}
                />
                <label htmlFor="input-correo">Correo o nombre de usuario</label>
            </div>
            { errors.correo && <p className="error-msg"><i className="material-icons">warning</i> { errors.correo.message }</p>}


            <div className="cont-inputs" style={ valCorreo ? { transform: 'scaleY(1)' } : { transform: 'scaleY(0)' } }>
              {/* Contraseña */}
              <div className="input-field input-outlined clave">
                  <i className="material-icons prefix" >vpn_key</i>
                  <input
                      className="validate"
                      id="input-pass"
                      type="password"
                      { ...register("clave", {
                          required: "Campo obligatorio",
                      })}
                  />
                  <label htmlFor="input-pass">Contraseña</label>
              </div>
              { errors.clave && <p className="error-msg" style={ valCorreo ? { visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' } }><i className="material-icons">warning</i> { errors.clave.message }</p>}


              {/* Captcha */}
              {/* <div className="cont-captcha">
                <div className="captcha">
                  Captcha
                </div>
              </div> */}

              <br />
              {/* Botón para ingresar */}
              <button disabled={ !valCorreo ? true : false } className="btn waves-effect waves-light btn-ingresar light-blue darken-4">
                Ingresar
              </button>
            </div>
        </form>

        <div className="cont-btn-validar"  style={ !valCorreo ? { marginTop: '-130px'} : { marginTop: '0'} }>
          <button
            id="btn-validar-correo"
            className={ valCorreo ? 'btn waves-effect waves-light btn-validar blue-grey darken-3' : 'btn waves-effect waves-light btn-validar light-blue darken-4' }
            onClick={ verificarCorreo }
            disabled={  estadoVal == 'no-validado' || estadoVal == 'validado' ? false : true }
          >
              {
                {
                  'no-validado': 'Validar',

                  'validando': 
                    <div className="progress">
                      <div className="indeterminate"></div>
                    </div>,

                  'validado': 'Volver'
                }[estadoVal]
              }
          </button>
        </div>

        <a href="#" className="recuperar-clave"  style={ valCorreo ? { visibility: 'visible', height: 'auto', opacity: '1' } : { visibility: 'hidden', height: '0' } }>Recuperar contraseña</a>
    </div>
  )
}
