import digitalizacionAPI from "../../API/digitalizacionAPI";

import M from "materialize-css";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { guardarCosecha } from "./guardarCosecha";
import { cerrarSesion } from "../../store/autentificacion/autentificacionSlice";

export const useCosecha = () => {
    const { token } = useSelector((state) => state.autentificacion);
    const conf_api = { headers: { 'x-token': token }};

    const { obtenerListaVariables, generarJSONTurnos, generarJSON } = guardarCosecha();

    const dispatch = useDispatch();
    
    const navigate = useNavigate()
    const moment = require('moment');

    const guardarCambios = async (produccion, sx, ewsx, lixiviacion ) => {
        let error = false;
        const conf = {
            headers: { 
                "x-token": token, 'Content-Type': 'application/json'  
            }
        };

        // =============== Variables SX
        const var_sx = obtenerListaVariables( sx );
        let { json_turno_a, json_turno_b, cambio_a, cambio_b } = generarJSONTurnos( var_sx );
        try {
            if( cambio_a ) {
                const res = await digitalizacionAPI.post(
                    '/reportes/cosecha/inputs/sx-ta', 
                    JSON.stringify( json_turno_a ), 
                    conf
                );
            }

            if( cambio_b ) {
                const res = await digitalizacionAPI.post(
                    '/reportes/cosecha/inputs/sx-tb', 
                    JSON.stringify( json_turno_b ), 
                    conf
                );
            }

        } catch( err ) {
            console.log( err );
            error = true;
        }

        // =============== Variables EW-SX
        const var_ewsx = obtenerListaVariables( ewsx );
        const { json, cambio } = generarJSON( var_ewsx );
        try {
            if( cambio ) {
                const res = await digitalizacionAPI.post(
                    '/reportes/cosecha/inputs/ew-sx', 
                    JSON.stringify( json ), 
                    conf
                );
            }

        } catch( err ) {
            console.log( err );
            error = true;
        }

        // =============== Variables lixiviación
        const var_lixiviacion = obtenerListaVariables( lixiviacion );
        let { json_turno_a:json_a, json_turno_b:json_b, cambio_a:cam_a, cambio_b:cam_b } = generarJSONTurnos( var_lixiviacion );
        try {
            if( cam_a ) {
                console.log(json_a);
                const res = await digitalizacionAPI.post(
                    '/reportes/cosecha/inputs/lix-ta', 
                    JSON.stringify( json_a ), 
                    conf
                );
            }

            if( cam_b ) {
                console.log(json_b);
                const res = await digitalizacionAPI.post(
                    '/reportes/cosecha/inputs/lix-tb', 
                    JSON.stringify( json_b ), 
                    conf
                );
            }

        } catch( err ) {
            console.log( err );
            error = true;
        }
        
        if( error ) { 
            M.toast({
                html: `<i class="material-icons">error</i> Ocurrió un error al registrar algunos cambios, recargue la página para validar`, 
                classes: 'rounded red'
            });

        } else {
            // navigate(0);
        }
    }

    const validarErrorConsulta = ( data ) => {
        switch( data ) {
            case 'Error en query':
                console.log('Existe un error en la query');
                break;

            case 'No encontrado':
                console.log('No se encontraron datos al consultar');
                break;

            case 'Token inválido | TokenExpiredError: jwt expired':
                localStorage.setItem("err-totken", true);
                dispatch( cerrarSesion() );
        }
    }

    const obtenerDatosGlobablesAPI = async () => {
        let datos = [];
        await digitalizacionAPI.get('/reportes/cosecha/globales', conf_api).then(res => {
            datos = res.data;

        }).catch(err => {
            validarErrorConsulta( err.response.data )
        });

        return datos;
    }

    const obtenerCosechaProduccionAPI = async () => {
        // console.log('produccion');
        let data = null;
        await digitalizacionAPI.get('/reportes/cosecha/prod', conf_api ).then( ({ data:res }) => {
            data = organizarProduccion(res);

        }).catch(err => {
            // console.log(err);
            validarErrorConsulta( err.response.data );
        });

        return data;
    }

    const guardarDatosGlobales = async () => {

        let data = {};

        let ef = document.getElementById('input-ef').value;
        let n_celdas = document.getElementById('input-n-celdas').value;
        let const_faraday = document.getElementById('input-faraday').value;

        let carg_a = document.getElementById('input-carg-a').value;
        let carg_b = document.getElementById('input-carg-b').value;
        let desc_a = document.getElementById('input-desc-a').value;
        let desc_b = document.getElementById('input-desc-b').value;

        data = {
            "ef": ef,
            "n_celdas": n_celdas,
            "const_faraday": const_faraday,
            "carg_a": carg_a,
            "carg_b": carg_b,
            "desc_a": desc_a,
            "desc_b": desc_b
        }
        const result = await digitalizacionAPI.post('/reportes/cosecha/inputs/global', data, { headers: { "x-token": token } });
        navigate(0)

    }

    const obtenerSXAPI = async () => {

        let dataSX = {};

        await digitalizacionAPI.get('/reportes/cosecha/sx', conf_api).then(({ data: res}) => {
            dataSX = {
                ...res
            }
        }).catch(err => {
            validarErrorConsulta(err.response.data);
        });

        const {
            flujos_pls_sx: pls,
            flujos_tren_a: tren_a,
            flujos_tren_b: tren_b 
        } = dataSX;


        const flujos = {
            ...pls,
            'tren_a':{
                ...tren_a
            },
            'tren_b':{
                ...tren_b
            }
        };

        const trenes = {
            'tren_a': {
                ...dataSX.tren_a
            },
            'tren_b':{
                ...dataSX.tren_b
            }
        }

        

        // // Cálculo total análisis
        const total_analisis = obtenerTotalAnalisisCISX(dataSX.analisis_cl, flujos);//optimizado

        // // Elec. pobre
        const tabla_elec_pobre = organizarElecPobreSX(dataSX.elec_pobre); //optimizado

        // // Laboratorio
        const tabla_laboratorio = organizarLaboratorioSX(dataSX.laboratorio);//optimizado

        // // EP ep_tk_102
        const tabla_ep = organizarEPSX(dataSX.ep_tk_102, flujos); //optimizado

        // // Arrastre
        const tabla_arrastre = organizarArrastreSX(dataSX.arrast_org);//optimizado

        // // Análisis CI
        const tabla_analisis = organizarAnalisisSX(dataSX.analisis_cl, total_analisis);//optimizado

        // // Flujos
        const tabla_flujos = organizarFlujosSX(flujos);//optimizado

        // // Trenes
        const tabla_turno_a = organizarTurnoASX(trenes);//optimizado
        
        const tabla_turno_b = organizarTurnoBSX(trenes);//optimizado

        // Trasnferencia horas
        const tabla_hora = organizarTransferenciasHorasSX(dataSX.transferencia);//optimizado

        return {
            tabla_flujos,

            tabla_turno_a,

            tabla_turno_b,

            tabla_arrastre,

            tabla_analisis,

            tabla_laboratorio,

            tabla_elec_pobre,

            tabla_hora,

            tabla_turnos: tabla_ep
        }
    }

    const obtenerEWSXAPI = async () => {
        let dataEWSX = {};

        await digitalizacionAPI.get('/reportes/cosecha/ew-sx', conf_api).then( ({ data: res }) => {
            dataEWSX = {
                ...res
            }
        }).catch(err => {
            // console.log(err);
            validarErrorConsulta( err.response.data )
        });

        // console.log(dataEWSX);

        // console.log(dataWESX_CTE);

        const tabla_turnos = organizarTablaTurnosEWSX(dataEWSX);
        const tabla_eficiencia_extraccion = organizarTablaEfExtracEWSX(dataEWSX);
        const tabla_eficiencia_reextracción = organizarTablaEfReExtracEWSX(dataEWSX);
        const tabla_extractores = organizarTablaExtractoresEWSX(dataEWSX);
        const tabla_cte_teorica = organizarTablaCteTeoricaEWSX(dataEWSX);
        const tabla_lvl_tks = organizarTablaLvlTKSEWSX(dataEWSX);
        const tabla_prod_planta = organizarTablaProdPlantaSEWSX(dataEWSX);

        return {
            tabla_turnos,
            tabla_eficiencia_extraccion,
            tabla_eficiencia_reextracción,
            tabla_extractores,
            tabla_cte_teorica,
            tabla_lvl_tks,
            tabla_prod_planta
        }

    }

    const obtenerLixAPI = async () => {

        let dataLix = {};

        await digitalizacionAPI.get('/reportes/cosecha/lixiviacion', conf_api).then(({ data: res }) => {
            dataLix = {
                ...res
            }
        }).catch(err => {
            validarErrorConsulta( err.response.data )
        })

        const tabla_lix = organizarTablaLix(dataLix);
        const tabla_acido = organizarTablaAcidoLix(dataLix);
        const tabla_lvl_agua = organizarTablaLvlAguaLix(dataLix);
        const tabla_agua_fresca = organizarTablaAguaFrescaLix(dataLix);
        const tabla_flujos = organizarTablaFlujoLix(dataLix);

        return {
            tabla_lix,
            tabla_acido,
            tabla_lvl_agua,
            tabla_agua_fresca,
            tabla_flujos
        }
    }

    const obtenerTotalAnalisisCISX = (datasx, flujos) => {

        let calculo1_ta = 0,
            calculo1_tb = 0;
        let calculo2_ta = 0,
            calculo2_tb = 0;
        let calculo3_ta = 0,
            calculo3_tb = 0;
        let total_09 = 0,
            total_21 = 0;

        const {
            er_a,
            er_b,
            ral_w1a,
            ral_w1b,
            ral_w2a,
            ral_w2b,
            ecc
        } = datasx;

        const {
            ecs_pls_sx
        } = flujos
        
        const {
            desc_ral_e1:ta_desc_ral_e1,
            desc_ep:ta_desc_ep,
            desc_ral_refino:ta_desc_ral_refino,
            desc_ral_tk102:ta_desc_ral_tk102
        } = flujos.tren_a;

        const {
            desc_ral_e1: tb_desc_ral_e1,
            desc_ep: tb_desc_ep,
            desc_ral_refino: tb_desc_ral_refino,
            desc_ral_tk102: tb_desc_ral_tk102
        } = flujos.tren_b;


        // total Turno A
        calculo1_ta = (ta_desc_ral_e1.ta + tb_desc_ral_e1.ta + ta_desc_ral_refino.ta + tb_desc_ral_refino.ta + ta_desc_ral_tk102.ta + tb_desc_ral_tk102.ta) * (ral_w1a.ta + ral_w1b.ta + ral_w2a.ta + ral_w2b.ta) / 2;
        calculo2_ta = ((er_a.ta + er_b.ta) / 2 - ecc.ta) * ecs_pls_sx.ta;
        calculo3_ta = (ta_desc_ep.ta + tb_desc_ep.ta) * ecc.ta;
        total_09 = calculo1_ta / (calculo1_ta + calculo2_ta - calculo3_ta);
        
        //Total Turno B
        calculo1_tb = (ta_desc_ral_e1.tb + tb_desc_ral_e1.tb + ta_desc_ral_refino.tb + tb_desc_ral_refino.tb + ta_desc_ral_tk102.tb + tb_desc_ral_tk102.tb) * (ral_w1a.tb + ral_w1b.tb + ral_w2a.tb + ral_w2b.tb) / 2;
        calculo2_tb = ((er_a.tb + er_b.tb) / 2 - ecc.tb) * ecs_pls_sx.tb;
        calculo3_tb = (ta_desc_ep.tb + tb_desc_ep.tb) * ecc.tb;
        total_21 = calculo1_tb / (calculo1_tb + calculo2_tb - calculo3_tb);

        if(isNaN(total_09)){ total_09 = 0 }
        if(isNaN(total_21)){ total_21 = 0 }
        
        return {
            total_turnos: {
                total_09: total_09,
                total_21: total_21
            }

        }
    }

    const organizarTablaTurnosEWSX = (datos) => {

        const {
            transferencia_total,
            cte_teorica,
            cte_real_promedio,
            deposito_real,
            descarte_ral,
            corte_sx_global,
            cte_teorica_sx
        } = datos;

        const tabla = {
            cabeceras: [
                [{
                        cols: 0,
                        valor: ''
                    },
                    {
                        cols: 1,
                        valor: 'TURNO A'
                    },
                    {
                        cols: 1,
                        valor: 'TURNO B'
                    }
                ]
            ],

            filas: [
                [{
                        valor: 'Transferencia total',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: transferencia_total.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: transferencia_total.turno_b,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Cte. teórica',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: cte_teorica.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: cte_teorica.turno_b,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Cte. real promedio',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: cte_real_promedio.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: cte_real_promedio.turno_b,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Depósito real',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: deposito_real.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: deposito_real.turno_b,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Descarte RAL',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: descarte_ral.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: descarte_ral.turno_b,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Corte SX global',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: corte_sx_global.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: corte_sx_global.turno_b,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Cte. teórica SX',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: cte_teorica_sx.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: cte_teorica_sx.turno_b,
                        clases_error: '',
                        input: false
                    }
                ]
            ]
        }

        return tabla;


    }

    const organizarTablaEfExtracEWSX = (datos) => {
        const {
            eficiencia_extraccion_tren_a,
            eficiencia_extraccion_tren_b,
            eficiencia_reextraccion

        } = datos;

        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'EFICIENCIA EXTRACCIÓN'
                    },
                    {
                        cols: 1,
                        valor: '09:00'
                    },
                    {
                        cols: 1,
                        valor: '21:00'
                    },
                    {
                        cols: 1,
                        valor: 'EFICIENCIA REEXTRACCIÓN'
                    }
                ]
            ],
            filas: [
                [{
                        valor: 'TREN A',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: eficiencia_extraccion_tren_a.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: eficiencia_extraccion_tren_a.turno_b,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: `${eficiencia_reextraccion.tren_a*100}%`,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'TREN B',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: eficiencia_extraccion_tren_b.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: eficiencia_extraccion_tren_b.turno_b,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: `${eficiencia_reextraccion.tren_b*100}%`,
                        clases_error: '',
                        input: false
                    }
                ]
            ]
        }

        return tabla;
    }

    const organizarTablaEfReExtracEWSX = (datos) => {
        const {
            eficiencia_reextraccion
        } = datos;

        const tabla = {
            cabeceras: [
                [{
                    cols: 2,
                    valor: 'EFICIENCIA REEXTRACCIÓN'
                }]
            ],
            filas: [
                [{
                        valor: 'TREN A',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: eficiencia_reextraccion.tren_a,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'TREN B',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: eficiencia_reextraccion.tren_b,
                        clases_error: '',
                        input: false
                    }
                ]
            ]
        }

        return tabla;
    }

    const organizarTablaExtractoresEWSX = (datos) => {
        const {
            extractores_fuera_servicio
        } = datos;

        const tabla = {
            cabeceras: [
                [{
                    cols: 2,
                    valor: 'EXTRACTORES FUERA DE SERVICIO'
                }]
            ],
            filas: [
                [{
                        valor: 'Sector norte',
                        clases_error: '',
                        input: false
                    },
                    {
                        original: extractores_fuera_servicio.norte,
                        valor: extractores_fuera_servicio.norte,
                        clases_error: '',
                        input: true,
                        tabla: 'tabla_extractores',
                        disabled: true,
                        llave: 'ext_sector_norte'
                    }
                ],
                [{
                        valor: 'Sector sur',
                        clases_error: '',
                        input: false
                    },
                    {
                        original: extractores_fuera_servicio.sur,
                        valor: extractores_fuera_servicio.sur,
                        clases_error: '',
                        input: true,
                        tabla: 'tabla_extractores',
                        disabled: true,
                        llave: 'ext_sector_sur'
                    }
                ]
            ]
        }

        return tabla;
    }

    const organizarTablaCteTeoricaEWSX = (datos) => {
        const {
            cte_puntual
        } = datos;

        const tabla = {
            cabeceras: [
                [{
                    cols: 2,
                    valor: 'CTE. TEÓRICA'
                }]
            ],
            filas: [
                [{
                        valor: 'Puntual 18:00',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: cte_puntual.puntual_18,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Puntual 06:00',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: cte_puntual.puntual_06,
                        clases_error: '',
                        input: false
                    }
                ]
            ]
        }

        return tabla;
    }

    const organizarTablaLvlTKSEWSX = (datos) => {
        const {
            nivel_tks_acumuladores
        } = datos;

        const tabla = {
            cabeceras: [
                []
            ],
            filas: [
                [{
                        valor: 'Nivel TKS acumuladores P.T.S.',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: nivel_tks_acumuladores,
                        clases_error: '',
                        input: false
                    }
                ]
            ]
        }

        return tabla;
    }

    const organizarTablaProdPlantaSEWSX = (datos) => {
        const {
            prod_planta_osmosis
        } = datos;

        const tabla = {
            cabeceras: [
                []
            ],
            filas: [
                [{
                        valor: 'Producción planta osmosis (m3/día)',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: prod_planta_osmosis,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        original: prod_planta_osmosis,
                        tabla: 'tabla_prod_planta',
                        llave: 'prod_osmosis'
                    }
                ]
            ]
        }

        return tabla;
    }

    const organizarTablaLix = (datos) => {

        const {
            canaleta_este,
            canaleta_oeste,
            mirador,
            pls_rom,
            flujo_riego,
            ils_gpl,
            mod_humectacion,
            modulos_en_riego,
            drenaje_total,
            modulos_drenaje,
            ils_m3h_rom
        } = datos;

        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'DATOS DE LIXIVIACIÓN'
                    },
                    {
                        cols: 1,
                        valor: 'TURNO A'
                    },
                    {
                        cols: 1,
                        valor: 'TURNO B'
                    }
                ]
            ],
            filas: [
                [{
                        valor: 'CANALETA  ESTE (gpl) ',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: canaleta_este.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: canaleta_este.turno_b,
                        clases_error: '',
                        input: false
                    },
                ],
                [{
                        valor: 'CANALETA  OESTE (gpl)',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: canaleta_oeste.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: canaleta_oeste.turno_b,
                        clases_error: '',
                        input: false
                    },
                ],
                [{
                        valor: 'MIRADOR (gpl)',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: mirador.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: mirador.turno_b,
                        clases_error: '',
                        input: false
                    },
                ],
                [{
                        valor: 'PLS ROM (gr/l)',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_rom.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_rom.turno_b,
                        clases_error: '',
                        input: false
                    },
                ],
                [{
                        valor: 'FLUJO RIEGO (M³/H) ROM',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: flujo_riego.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: flujo_riego.turno_b,
                        clases_error: '',
                        input: false
                    },
                ],
                [{
                        valor: 'ILS (gpl)',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ils_gpl.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ils_gpl.turno_b,
                        clases_error: '',
                        input: false
                    },
                ],
                [{
                        valor: 'MOD HUMECTACIÓN',
                        clases_error: '',
                        input: false
                    },
                    {
                        original: mod_humectacion.turno_a,
                        valor: mod_humectacion.turno_a,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: 'tabla_lix',
                        llave: 'mod_humectacion_10',
                        turno: 'a'
                    },
                    {
                        original: mod_humectacion.turno_b,
                        valor: mod_humectacion.turno_b,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: 'tabla_lix',
                        llave: 'mod_humectacion_22',
                        turno: 'b'
                    },
                ],
                [{
                        valor: 'MODULOS EN RIEGO (din)',
                        clases_error: '',
                        input: false
                    },
                    {
                        original: modulos_en_riego.turno_a,
                        valor: modulos_en_riego.turno_a,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: 'tabla_lix',
                        llave: 'modulos_en_riego_10',
                        turno: 'a'
                    },
                    {
                        original: modulos_en_riego.turno_b,
                        valor: modulos_en_riego.turno_b,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: 'tabla_lix',
                        llave: 'modulos_en_riego_22',
                        turno: 'b'
                    },
                ],
                [{
                        valor: 'DRENAJE TOTAL DIN+MIR',
                        clases_error: '',
                        input: false
                    },
                    {
                        original: drenaje_total.turno_a,
                        valor: drenaje_total.turno_a,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: 'tabla_lix',
                        llave: 'drenaje_total_10',
                        turno: 'a'
                    },
                    {
                        original: drenaje_total.turno_b,
                        valor: drenaje_total.turno_b,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: 'tabla_lix',
                        llave: 'drenaje_total_22',
                        turno: 'b'
                    },
                ],
                [{
                        valor: 'MOD. DRENAJE',
                        clases_error: '',
                        input: false
                    },
                    {
                        original: modulos_drenaje.turno_a,
                        valor: modulos_drenaje.turno_a,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: 'tabla_lix',
                        llave: 'modulos_drenaje_10',
                        turno: 'a'
                    },
                    {
                        original: modulos_drenaje.turno_b,
                        valor: modulos_drenaje.turno_b,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: 'tabla_lix',
                        llave: 'modulos_drenaje_22',
                        turno: 'b'
                    },
                ],
                [{
                        valor: 'ILS (m3/h) (ROM)',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ils_m3h_rom.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ils_m3h_rom.turno_b,
                        clases_error: '',
                        input: false
                    },
                ]
            ]
        }

        return tabla;
    }

    const organizarTablaAcidoLix = (datos) => {
        const {
            tk_005,
            tk_013,
            tk_034,
            tk_035
        } = datos;

        const tabla = {
            cabeceras: [
                [{
                    cols: 3,
                    valor: 'NIVELES ÁCIDO'
                }]
            ],

            filas: [
                [{
                        valor: 'TK-005',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_005.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_005.turno_b,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'TK-013',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_013.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_013.turno_b,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'TK-034',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_034.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_034.turno_b,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'TK-035',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_035.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_035.turno_b,
                        clases_error: '',
                        input: false
                    }
                ]
            ]
        }
        return tabla;
    }

    const organizarTablaLvlAguaLix = (datos) => {

        const {
            tk_003,
            tk_04
        } = datos;

        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'NIVELES AGUA'
                    },
                    {
                        cols: 1,
                        valor: 'TURNO A'
                    },
                    {
                        cols: 1,
                        valor: 'TURNO B'
                    }
                ]
            ],

            filas: [
                [{
                        valor: 'TK-003',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_003.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_003.turno_b,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'TK-04',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_04.turno_a,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tk_04.turno_b,
                        clases_error: '',
                        input: false
                    }
                ]
            ]
        }
        return tabla;
    }

    const organizarTablaAguaFrescaLix = (datos) => {

        const {
            piscina_1,
            piscina_2
        } = datos;

        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'NIVEL AGUA FRESCA'
                    },
                    {
                        cols: 1,
                        valor: 'TURNO A'
                    },
                    {
                        cols: 1,
                        valor: 'TURNO B'
                    }
                ]
            ],

            filas: [
                [{
                        valor: 'PISCINA 1',
                        clases_error: '',
                        input: false
                    },
                    {
                        tabla: 'tabla_agua_fresca',
                        original: piscina_1.turno_a,
                        valor: piscina_1.turno_a,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        llave: 'piscina_1_10',
                        turno: 'a'
                    },
                    {
                        tabla: 'tabla_agua_fresca',
                        original: piscina_1.turno_b,
                        valor: piscina_1.turno_b,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        llave: 'piscina_1_22',
                        turno: 'b'
                    }
                ],
                [{
                        valor: 'PISCINA 2',
                        clases_error: '',
                        input: false
                    },
                    {
                        tabla: 'tabla_agua_fresca',
                        original: piscina_2.turno_a,
                        valor: piscina_2.turno_a,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        llave: 'piscina_2_10',
                        turno: 'a'
                    },
                    {
                        tabla: 'tabla_agua_fresca',
                        original: piscina_2.turno_b,
                        valor: piscina_2.turno_b,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        llave: 'piscina_2_22',
                        turno: 'b'
                    }
                ]
            ]
        }
        return tabla;
    }

    const organizarTablaFlujoLix = (datos) => {

        const {
            pls_oxe,
            refino_oxe
        } = datos;

        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'FLUJO (m3/h)'
                    },
                    {
                        cols: 1,
                        valor: 'TURNO A'
                    }
                ]
            ],

            filas: [
                [{
                        valor: 'PLS OXE',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_oxe.turno_a,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'REFINO OXE',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: refino_oxe.turno_a,
                        clases_error: '',
                        input: false
                    }
                ]
            ]
        }
        return tabla;
    }

    const organizarTransferenciasHorasSX = (data) => {
        // console.log(turno_a);
        // console.log(turno_b)

        const {
            transf_tren_a,
            transf_tren_b,
            transf_ral_sx,
            transf_ral_ref,
            ef_tren_a_e1a,
            ef_tren_a_e2a,
            ef_tren_b_e1b,
            ef_tren_b_e2b,
            tn_a_gpl,
            tn_b_gpl
        } = data;

        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'HORA'
                    },
                    {
                        cols: 1,
                        valor: '09:00'
                    },
                    {
                        cols: 1,
                        valor: '21:00'
                    }
                ]
            ],

            filas: [
                [{
                        valor: 'Transf. tren A',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: transf_tren_a.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: transf_tren_a.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Transf. tren B',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: transf_tren_b.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: transf_tren_b.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Transf. RAL a SX',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: transf_ral_sx.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: transf_ral_sx.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Transf. RAL a Ref',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: transf_ral_ref.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: transf_ral_ref.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Ef. tren A (E1-A)',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ef_tren_a_e1a.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ef_tren_a_e1a.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Ef. tren B (E1-B)',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ef_tren_b_e1b.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ef_tren_b_e1b.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Ef. tren A (E2-A)',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ef_tren_a_e2a.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ef_tren_a_e2a.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Ef. tren B (E2-B)',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ef_tren_b_e2b.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ef_tren_b_e2b.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'T.N."A" = gpl/%v/v',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tn_a_gpl.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tn_a_gpl.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'T.N."B" = gpl/%v/v',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tn_b_gpl.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: tn_b_gpl.tb,
                        clases_error: '',
                        input: false
                    }
                ]
            ]
        };

        return tabla;
    }

    const organizarTurnoBSX = (trenes) => {

        
        const {
            pls: pls_ta,
            ref_e2: ref_e2_ta,
            ref_e3: ref_e3_ta,
            ref_lix: ref_lix_ta,
            ep: ep_ta,
            ecc: ecc_ta,
            er: er_ta,
            ral_w1: ral_w1_ta,
            ral_w2: ral_w2_ta,
            balance_ep: balance_ep_ta
        } = trenes.tren_a;

        const {
            ref_e2: ref_e2_tb,
            ref_e3: ref_e3_tb,
            er: er_tb,
            ral_w1: ral_w1_tb,
            ral_w2: ral_w2_tb
        } = trenes.tren_b;
        
        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'TURNO-A'
                    },
                    {
                        cols: 3,
                        valor: 'TREN A'
                    },
                    {
                        cols: 3,
                        valor: 'TREN B'
                    }
                ],
                [{
                        cols: 1,
                        valor: '21:00 HRS'
                    },
                    {
                        cols: 1,
                        valor: 'CU++'
                    },
                    {
                        cols: 1,
                        valor: 'H+'
                    },
                    {
                        cols: 1,
                        valor: 'PH'
                    },
                    {
                        cols: 1,
                        valor: 'CU++'
                    },
                    {
                        cols: 1,
                        valor: 'H+'
                    },
                    {
                        cols: 1,
                        valor: 'PH'
                    }
                ]
            ],

            filas: [
                [{
                        valor: 'PLS',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_ta.cu_pls.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_ta.h_pls.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_ta.ph_pls.tb,
                        clases_error: '',
                        input: false
                    },

                    {
                        valor: pls_ta.cu_pls.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_ta.h_pls.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_ta.ph_pls.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'REF E-2',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e2_ta.cu_ref_e2.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e2_ta.h_ref_e2.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e2_ta.ph_ref_e2.tb,
                        clases_error: '',
                        input: false
                    },

                    {
                        valor: ref_e2_tb.cu_ref_e2.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e2_tb.h_ref_e2.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e2_tb.ph_ref_e2.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'REF E-3',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e3_ta.cu_ref_e3.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e3_ta.h_ref_e3.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e3_ta.ph_ref_e3.tb,
                        clases_error: '',
                        input: false
                    },

                    {
                        valor: ref_e3_tb.cu_ref_e3.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e3_tb.h_ref_e3.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e3_tb.ph_ref_e3.tb,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'REF LIX',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_lix_ta.cu_ref_lix.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_lix_ta.h_ref_lix.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_lix_ta.ph_ref_lix.tb,
                        clases_error: '',
                        input: false
                    }, // Corresponde a un tag de análisis químico

                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'EP',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ep_ta.cu_ep.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ep_ta.h_ep.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'ECC',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ecc_ta.cu_ecc.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ecc_ta.h_ecc.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'ER',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: er_ta.cu_er.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: er_ta.h_er.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: er_tb.cu_er.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: er_tb.h_er.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'RAL W-1',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w1_ta.cu_ral_w1.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w1_ta.h_ral_w1.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },

                    {
                        valor: ral_w1_tb.cu_ral_w1.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w1_tb.h_ral_w1.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'RAL W-2',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w2_ta.cu_ral_w2.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w2_ta.h_ral_w2.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },

                    {
                        valor: ral_w2_tb.cu_ral_w2.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w2_tb.h_ral_w2.tb,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Balace ácido EP',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: balance_ep_ta.tb,
                        clases_error: '',
                        input: false
                    }
                ],
            ]
        }

        return tabla;
    }

    const organizarTurnoASX = (trenes) => {
        const {
            pls: pls_ta,
            ref_e2: ref_e2_ta,
            ref_e3: ref_e3_ta,
            ref_lix: ref_lix_ta,
            ep: ep_ta,
            ecc: ecc_ta,
            er: er_ta,
            ral_w1: ral_w1_ta,
            ral_w2: ral_w2_ta,
            balance_ep: balance_ep_ta
        } = trenes.tren_a;

        const {
            ref_e2: ref_e2_tb,
            ref_e3: ref_e3_tb,
            er: er_tb,
            ral_w1: ral_w1_tb,
            ral_w2: ral_w2_tb
        } = trenes.tren_b;

        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'TURNO-A'
                    },
                    {
                        cols: 3,
                        valor: 'TREN A'
                    },
                    {
                        cols: 3,
                        valor: 'TREN B'
                    }
                ],
                [{
                        cols: 1,
                        valor: '09:00 HRS'
                    },
                    {
                        cols: 1,
                        valor: 'CU++'
                    },
                    {
                        cols: 1,
                        valor: 'H+'
                    },
                    {
                        cols: 1,
                        valor: 'PH'
                    },
                    {
                        cols: 1,
                        valor: 'CU++'
                    },
                    {
                        cols: 1,
                        valor: 'H+'
                    },
                    {
                        cols: 1,
                        valor: 'PH'
                    }
                ]
            ],

            filas: [
                [{
                        valor: 'PLS',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_ta.cu_pls.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_ta.h_pls.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_ta.ph_pls.ta,
                        clases_error: '',
                        input: false
                    },

                    {
                        valor: pls_ta.cu_pls.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_ta.h_pls.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: pls_ta.ph_pls.ta,
                        clases_error: '',
                        input: false
                    },
                ],
                [{
                        valor: 'REF E-2',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e2_ta.cu_ref_e2.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e2_ta.h_ref_e2.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e2_ta.ph_ref_e2.ta,
                        clases_error: '',
                        input: false
                    },

                    {
                        valor: ref_e2_tb.cu_ref_e2.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e2_tb.h_ref_e2.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e2_tb.ph_ref_e2.ta,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'REF E-3',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e3_ta.cu_ref_e3.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e3_ta.h_ref_e3.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e3_ta.ph_ref_e3.ta,
                        clases_error: '',
                        input: false
                    },

                    {
                        valor: ref_e3_tb.cu_ref_e3.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e3_tb.h_ref_e3.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_e3_tb.ph_ref_e3.ta,
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'REF LIX',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_lix_ta.cu_ref_lix.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_lix_ta.h_ref_lix.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ref_lix_ta.ph_ref_lix.ta,
                        clases_error: '',
                        input: false
                    }, // Corresponde a un tag de análisis químico

                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'EP',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ep_ta.cu_ep.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ep_ta.h_ep.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'ECC',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ecc_ta.cu_ecc.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ecc_ta.h_ecc.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'ER',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: er_ta.cu_er.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: er_ta.h_er.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: er_tb.cu_er.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: er_tb.h_er.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'RAL W-1',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w1_ta.cu_ral_w1.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w1_ta.h_ral_w1.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },

                    {
                        valor: ral_w1_tb.cu_ral_w1.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w1_tb.h_ral_w1.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'RAL W-2',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w2_ta.cu_ral_w2.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w2_ta.h_ral_w2.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    },

                    {
                        valor: ral_w2_tb.cu_ral_w2.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: ral_w2_tb.h_ral_w2.ta,
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false
                    }
                ],
                [{
                        valor: 'Balace ácido EP',
                        clases_error: '',
                        input: false
                    },
                    {
                        valor: balance_ep_ta.ta,
                        clases_error: '',
                        input: false
                    }
                ],
            ]
        }

        return tabla;
    }

    const organizarFlujosSX = (flujos) => {

        const {
            ecc_pls_sx,
            ecs_pls_sx,
            ref_lix_mco_pls_sx,
            ref_lix_oxe_pls_sx
        } = flujos;
        
        const {
            pls_e1: pls_e1_ta,
            pls_e2: pls_e2_ta,
            org_cargado: org_cargado_ta,
            ep: ep_ta,
            desc_ral_refino: desc_ral_refino_ta,
            desc_ep: desc_ep_ta,
            desc_ral_tk102: desc_ral_tk102_ta,
            desc_ral_e1: desc_ral_e1_ta,
        } = flujos.tren_a;
       
        const {
            pls_e1: pls_e1_tb,
            pls_e2: pls_e2_tb,
            org_cargado: org_cargado_tb,
            ep: ep_tb,
            desc_ral_refino: desc_ral_refino_tb,
            desc_ep: desc_ep_tb,
            desc_ral_tk102: desc_ral_tk102_tb,
            desc_ral_e1: desc_ral_e1_tb,
        } = flujos.tren_b;
        
        const tabla = {
            cabeceras: [
                [{
                        cols: 0,
                        valor: ''
                    },
                    {
                        cols: 3,
                        valor: 'TURNO A'
                    },
                    {
                        cols: 3,
                        valor: 'TURNO B'
                    }
                ],
                [{
                        cols: 1,
                        valor: 'FLUJOS'
                    },
                    {
                        cols: 1,
                        valor: 'TREN-A'
                    },
                    {
                        cols: 1,
                        valor: 'PLS SX'
                    },
                    {
                        cols: 1,
                        valor: 'TREN-B'
                    },
                    {
                        cols: 1,
                        valor: 'TREN-A'
                    },
                    {
                        cols: 1,
                        valor: 'PLS SX'
                    },
                    {
                        cols: 1,
                        valor: 'TREN-B'
                    }
                ]
            ],
            filas: [
                [{
                        valor: 'PLS E-1',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((pls_e1_ta.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((pls_e1_ta.ta + pls_e2_ta.ta + pls_e1_tb.ta + pls_e2_tb.ta).toFixed(2)),
                        rows: 2,
                        clases_error: '',
                        input: false
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((pls_e1_tb.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },

                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((pls_e1_ta.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((pls_e1_ta.tb + pls_e2_ta.tb + pls_e1_tb.tb + pls_e2_tb.tb).toFixed(2)),
                        rows: 2,
                        clases_error: '',
                        input: false
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((pls_e1_tb.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'PLS E-2',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((pls_e2_ta.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    //   { valor: '', rows: 1, clases_error: '', input: false },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((pls_e2_tb.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },

                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((pls_e2_ta.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    //   { valor: '', rows: 1, clases_error: '', input: false },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((pls_e2_tb.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'ORG. Cargado',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((org_cargado_ta.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((org_cargado_tb.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },

                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((org_cargado_ta.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((org_cargado_tb.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'EP',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((ep_ta.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((ep_tb.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },

                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((ep_ta.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((ep_tb.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'ECC',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((ecc_pls_sx.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },

                    {
                        clases: 'turno-b-flujos',
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((ecc_pls_sx.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'ECS',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((ecs_pls_sx.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },

                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((ecs_pls_sx.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'Ref. a Lix MCO',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((ref_lix_mco_pls_sx.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },

                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((ref_lix_mco_pls_sx.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'Ref. a Lix OXE',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((ref_lix_oxe_pls_sx.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },

                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((ref_lix_oxe_pls_sx.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'Desc. EP',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((desc_ep_ta.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((desc_ep_tb.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },

                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((desc_ep_ta.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((desc_ep_tb.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'Desc. RAL a refino',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos', // TREN A
                        original: desc_ral_refino_ta.ta,
                        valor: desc_ral_refino_ta.ta,
                        input: true,
                        disabled: true,
                        tabla: 'tabla_flujos',
                        llave: "desc_ral_refino_ta_19",
                        turno: "a"
                    },
                    {
                        valor: 'no valido',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos', // TREN B
                        original: desc_ral_refino_tb.ta,
                        valor: desc_ral_refino_tb.ta,
                        input: true,
                        disabled: true,
                        tabla: 'tabla_flujos',
                        llave: "desc_ral_refino_tb_19",
                        turno: "a",
                        rows: 1
                    },

                    {
                        clases: 'turno-b-flujos', // TREN A
                        original: desc_ral_refino_ta.tb,
                        valor: desc_ral_refino_ta.tb,
                        input: true,
                        disabled: true,
                        tabla: 'tabla_flujos',
                        llave: "desc_ral_refino_ta_07",
                        turno: "b",
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos', // TREN B
                        original: desc_ral_refino_tb.tb,
                        valor: desc_ral_refino_tb.tb,
                        input: true,
                        disabled: true,
                        tabla: 'tabla_flujos',
                        llave: "desc_ral_refino_tb_07",
                        turno: "b",
                        rows: 1
                    }
                ],
                [{
                        valor: 'Desc. RAL a E-1',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((desc_ral_e1_ta.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        valor: parseFloat((desc_ral_e1_tb.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },

                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((desc_ral_e1_ta.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: 'no valido',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        valor: parseFloat((desc_ral_e1_tb.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'Desc. RAL a TK-102',
                        input: false,
                        rows: 1
                    },
                    { 
                        clases: 'turno-a-flujos',
                        original: desc_ral_tk102_ta.ta,
                        valor: desc_ral_tk102_ta.ta,
                        input: true,
                        disabled: true,
                        tabla: 'tabla_flujos',
                        llave: "desc_ral_tk102_ta_19",
                        turno: "a"
                    },
                    {
                        valor: 'no valido',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-a-flujos',
                        original: desc_ral_tk102_tb.ta,
                        valor: desc_ral_tk102_tb.ta,
                        input: true,
                        disabled: true,
                        tabla: 'tabla_flujos',
                        llave: "desc_ral_tk102_tb_19",
                        turno: "a"
                    },

                    {
                        clases: 'turno-b-flujos',
                        original: desc_ral_tk102_ta.tb,
                        valor: desc_ral_tk102_ta.tb,
                        input: true,
                        disabled: true,
                        tabla: 'tabla_flujos',
                        llave: "desc_ral_tk102_ta_07",
                        turno: "b"
                    },
                    {
                        valor: 'no valido',
                        input: false,
                        rows: 1
                    },
                    {
                        clases: 'turno-b-flujos',
                        original: desc_ral_tk102_tb.tb,
                        valor: desc_ral_tk102_tb.tb,
                        input: true,
                        disabled: true,
                        tabla: 'tabla_flujos',
                        llave: "desc_ral_tk102_tb_07",
                        turno: "b"
                    }
                ]
            ]
        }

        return tabla;
    }

    const organizarAnalisisSX = (datasx, total_analisis) => {


        const {
            er_a,
            er_b,
            ral_w1a,
            ral_w1b,
            ral_w2a,
            ral_w2b,
            ecc,
            ep,
            pls
        } = datasx;
        
        const {
            total_turnos
        } = total_analisis

        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'ANALISIS [CI-]'
                    },
                    {
                        cols: 1,
                        valor: '09:00'
                    },
                    {
                        cols: 1,
                        valor: '21:00'
                    }
                ]
            ],
            filas: [
                [{
                        valor: 'ER-A',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: er_a.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: er_a.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'ER-B',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: er_b.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: er_b.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'RAL W-1 A',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ral_w1a.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ral_w1a.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'RAL W-1 B',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ral_w1b.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ral_w1b.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'RAL W-2 A',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ral_w2a.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ral_w2a.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'RAL W-2 B',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ral_w2b.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ral_w2b.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'ECC',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ecc.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ecc.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'EP',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ep.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ep.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'PLS',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: parseFloat(pls.ta),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: parseFloat(pls.tb),
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'TOTAL',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: (total_turnos.total_09).toFixed(2),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: (total_turnos.total_21).toFixed(2),
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ]
            ]
        }

        return tabla;
    }

    const organizarEPSX = (datasx, flujo) => {

        const {
            ep_tk_102,
            otros_desc_ep
        } = datasx;

        const {
            desc_ep: ta_desc_ep

        } = flujo.tren_a;
        
        const {
            desc_ep: tb_desc_ep

        } = flujo.tren_b;
        
        const tabla = {
            cabeceras: [
                [{
                        cols: 0,
                        valor: ''
                    },
                    {
                        cols: 1,
                        valor: 'TURNO A'
                    },
                    {
                        cols: 1,
                        valor: 'TURNO B'
                    }
                ],
            ],
            filas: [
                [{
                        valor: 'EP a TK-102',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ep_tk_102.ta,
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ep_tk_102.tb,
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'OTROS DESC EP',
                        input: false,
                        rows: 1
                    },
                    {
                        original: otros_desc_ep.ta,
                        valor: otros_desc_ep.ta,
                        input: true,
                        disabled: true,
                        tabla: "tabla_turnos",
                        llave: "desc_ep_19",
                        turno: "a"
                    },
                    {
                        original: otros_desc_ep.tb,
                        valor: otros_desc_ep.tb,
                        input: true,
                        disabled: true,
                        tabla: "tabla_turnos",
                        llave: "desc_ep_07",
                        turno: "b"
                    }
                ],
                [{
                        valor: 'TOTAL DESC EP',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: (ep_tk_102.ta + otros_desc_ep.ta + ta_desc_ep.ta + tb_desc_ep.ta),
                        input: false,
                        rows: 1
                    },
                    {
                        valor: (ep_tk_102.tb + otros_desc_ep.tb + ta_desc_ep.tb + tb_desc_ep.tb),
                        input: false,
                        rows: 1
                    },
                ]
            ]
        }

        return tabla;
    }

    const organizarLaboratorioSX = (datasx) => {

        const {
            pls,
            nivel_pls
        } = datasx;

        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'LABORATORIO'
                    },
                    {
                        cols: 1,
                        valor: '18:00'
                    },
                    {
                        cols: 1,
                        valor: '06:00'
                    }
                ]
            ],

            filas: [
                [{
                        valor: 'PLS',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: parseFloat((pls.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: parseFloat((pls.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'Nivel PLS',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: parseFloat((nivel_pls.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: parseFloat((nivel_pls.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ]
            ]
        }

        return tabla;
    }

    const organizarElecPobreSX = (datasx) => {

        const {
            elp_cu,
            elp_h
        } = datasx;
        
        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'ELEC. POBRE'
                    },
                    {
                        cols: 1,
                        valor: '18:00'
                    },
                    {
                        cols: 1,
                        valor: '06:00'
                    }
                ]
            ],

            filas: [
                [{
                        valor: 'Cu++',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: elp_cu.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: elp_cu.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'H++',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: elp_h.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: elp_h.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ],
                [{
                        valor: 'Balance',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: parseFloat(((elp_cu.ta * 1.54) + elp_h.ta).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: parseFloat(((elp_cu.tb * 1.54) + elp_h.tb).toFixed(2)),
                        clases_error: '',
                        input: false,
                        rows: 1
                    }
                ]
            ]
        }

        return tabla;
    }

    const organizarArrastreSX = (datasx) => {

        const {
            ep,
            ecc,
            ecl,
            s1a,
            s1b,
            e2a,
            e2b,
            e3a,
            e3b,
            ref_p,
            out_fl_gral
        } = datasx;
        
        const tabla = {
            cabeceras: [
                [{
                        cols: 1,
                        valor: 'ARRAST. ORG'
                    },
                    {
                        cols: 1,
                        valor: '10:00'
                    },
                    {
                        cols: 1,
                        valor: '22:00'
                    }
                ]
            ],
            filas: [
                [{
                        valor: 'EP',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ep.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ep.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'ECC',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ecc.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ecc.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'ECL',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ecl.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ecl.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'S1A',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: s1a.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: s1a.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'S1B',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: s1b.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: s1b.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'E2A',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: e2a.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: e2a.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'E2B',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: e2b.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: e2b.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'E3A',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        original: e3a.ta,
                        valor: e3a.ta,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: "tabla_arrastre",
                        llave: "ao_e3a_10",
                        turno: "a"
                    },
                    {
                        original: e3a.tb,
                        valor: e3a.tb,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: "tabla_arrastre",
                        llave: "ao_e3a_22",
                        turno: "b"
                    },
                ],
                [{
                        valor: 'E3B',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        original: e3b.ta,
                        valor: e3b.ta,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: "tabla_arrastre",
                        llave: "ao_e3b_10",
                        turno: "a"
                    },
                    {
                        original: e3b.tb,
                        valor: e3b.tb,
                        clases_error: '',
                        input: true,
                        disabled: true,
                        tabla: "tabla_arrastre",
                        llave: "ao_e3b_22",
                        turno: "b"
                    },
                ],
                [{
                        valor: 'REF. P',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ref_p.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: ref_p.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ],
                [{
                        valor: 'OUT. FL. FRAL.',
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: out_fl_gral.ta,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                    {
                        valor: out_fl_gral.tb,
                        clases_error: '',
                        input: false,
                        rows: 1
                    },
                ]
            ]
        };

        return tabla;
    }

    const organizarProduccion = (datos) => {
        let datos_agrupados = [];
        const meses = obtenerMesesCosecha(datos);
        let fechas = [];

        // console.log(datos);
        // Agrupar datos por meses
        // console.log(datos);
        meses.forEach(({ num, mes }) => {
            let filas = [];
            
            let pos = 0;

            datos.forEach(({ fecha, cosecha_turno_a, cosecha_dia, plan_maestro, ppto_met, ppto_oxe }) => {
                const { fecha_fila, mes_fila, dia_fila, ultimo_dia } = generarVariablesFila(fecha);

                // if( fecha_fila == '2023-10-03') console.log({ fecha, cosecha_turno_a, cosecha_dia, plan_maestro, ppto_met, ppto_oxe })
                if (mes_fila == num && !fechas.includes(fecha_fila)) {
                    const cosecha_a = Math.round((cosecha_turno_a / 1000));
                    const cosecha_b = Math.round((cosecha_dia - cosecha_turno_a) / 1000) < 0 ? 0 : Math.round((cosecha_dia - cosecha_turno_a) / 1000);
                    const valor_cosecha_dia = cosecha_b == 0 ? cosecha_a : cosecha_a == 0 ? cosecha_b : Math.round(cosecha_dia / 1000);
                    const cosecha_real = (dia_fila == 1 || filas.length == 0) ?
                        valor_cosecha_dia :
                        Math.round(filas[filas.length - 1].cosecha_real + valor_cosecha_dia);

                    const presupuesto = Math.round((ppto_met + ppto_oxe) / ultimo_dia * dia_fila);
                    const var_presupuesto = cosecha_real - presupuesto

                    const valor_plan_maestro = (dia_fila == 1 || filas.length == 0) ?
                        Math.round(plan_maestro) :
                        Math.round(filas[filas.length - 1].plan_maestro + plan_maestro);
                    const var_plan_maestro = ((dia_fila == 1 || filas.length == 0) ?
                        Math.round(cosecha_real - plan_maestro) :
                        Math.round(cosecha_real - valor_plan_maestro));

                    // Filas nómina
                    filas = [...filas, {
                        fecha: fecha_fila,
                        cosecha_a,
                        cosecha_b,
                        cosecha_dia: valor_cosecha_dia,
                        cosecha_real,
                        presupuesto,
                        var_presupuesto,
                        plan_maestro: valor_plan_maestro,
                        var_plan_maestro
                    }];

                    // console.log(filas);

                    pos += 1;
                }
                
            });

            datos_agrupados.push({
                num,
                mes,
                filas
            });
        });

        const informe_mensual = generarDatosMensual(datos_agrupados);
        const informe_anual = generarDatosAnual(datos_agrupados);

        return {
            datos_agrupados,
            informe_mensual,
            informe_anual
        };
    }

    const generarDatosAnual = (datos) => {
        // Calcular suma mensual
        let datos_meses = [];

        let total_cosecha_a = 0;
        let total_cosecha_b = 0;
        let total_cosecha_dia = 0;
        let total_cosecha_real = 0;
        let total_presupuesto = 0;
        let total_var_presupuesto = 0;
        let total_plan_maestro = 0;
        let total_var_plan_maestro = 0;

        let serie_real = [];
        let serie_presupuesto = [];
        let serie_plan_maestro = [];
        let meses = [];

        datos.forEach(({ filas, mes, num }) => {
            meses = [ ...meses, mes ];
            let suma_cosecha_a = 0;
            let suma_cosecha_b = 0;
            let suma_cosecha_dia = 0;
            let suma_cosecha_real = 0;
            let suma_presupuesto = 0;
            let suma_var_presupuesto = 0;
            let suma_plan_maestro = 0;
            let suma_var_plan_maestro = 0;

            filas.forEach(({ cosecha_a, cosecha_b, cosecha_dia, cosecha_real, presupuesto, plan_maestro }) => {
                suma_cosecha_a = suma_cosecha_a + cosecha_a;
                suma_cosecha_b = suma_cosecha_b + cosecha_b;
                suma_cosecha_dia = suma_cosecha_dia + cosecha_dia;
                suma_cosecha_real = cosecha_real;
                suma_presupuesto = presupuesto;
                suma_var_presupuesto = cosecha_real - presupuesto;
                suma_plan_maestro = plan_maestro;
                suma_var_plan_maestro = cosecha_real - plan_maestro;
            });

            // Guardar fila de mes
            datos_meses = [...datos_meses, {
                mes: mes,
                cosecha_a: suma_cosecha_a,
                cosecha_b: suma_cosecha_b,
                cosecha_dia: suma_cosecha_dia,
                cosecha_real: suma_cosecha_real,
                presupuesto: suma_presupuesto,
                var_presupuesto: suma_var_presupuesto,
                plan_maestro: suma_plan_maestro,
                var_plan_maestro: suma_var_plan_maestro
            }];

            serie_real = [...serie_real, suma_cosecha_real];
            serie_presupuesto = [...serie_presupuesto, suma_presupuesto];
            serie_plan_maestro = [...serie_plan_maestro, suma_plan_maestro];

            // Sumar para fila Year to date
            total_cosecha_a += suma_cosecha_a;
            total_cosecha_b += suma_cosecha_b;
            total_cosecha_dia += suma_cosecha_dia;
            total_cosecha_real += suma_cosecha_real;
            total_presupuesto += suma_presupuesto;
            total_var_presupuesto += suma_var_presupuesto;
            total_plan_maestro += suma_plan_maestro;
            total_var_plan_maestro += suma_var_plan_maestro;

        });

        return {
            nomina: {
                filas: datos_meses,
                fila_total: {
                    texto: 'YEAR TO DATE',
                    total_cosecha_a: total_cosecha_a,
                    total_cosecha_b: total_cosecha_b,
                    total_cosecha_dia: total_cosecha_dia,
                    total_cosecha_real: total_cosecha_real,
                    total_presupuesto: total_presupuesto,
                    total_var_presupuesto: total_var_presupuesto,
                    total_plan_maestro: total_plan_maestro,
                    total_var_plan_maestro: total_var_plan_maestro,
                }
            },
            grafico: {
                meses,
                serie_real,
                serie_presupuesto,
                serie_plan_maestro,
                total_cosecha_real,
                total_presupuesto,
                total_plan_maestro
            }
        }
    }

    const validarSinValor = ( fila ) => {
        const { cosecha_a, cosecha_b, cosecha_dia, cosecha_real, presupuesto, plan_maestro } = validarSinValor( fila );

        return {
            cosecha_a: cosecha_a == '-' ? 0 : cosecha_a,
            cosecha_b: cosecha_b == '-' ? 0 : cosecha_b,
            cosecha_dia: cosecha_dia == '-' ? 0 : cosecha_dia,
            cosecha_real: cosecha_real == '-' ? 0 : cosecha_real,
            presupuesto: presupuesto == '-' ? 0 : presupuesto,
            plan_maestro: plan_maestro == '-' ? 0 : plan_maestro,
        }
    }

    const generarDatosMensual = (datos) => {
        // Generar datos nómina mensual
        let serie_real = [];
        let serie_presupuesto = [];
        let serie_plan_maestro = [];
        let dias = [];

        let total_cosecha_a = 0;
        let total_cosecha_b = 0;
        let total_cosecha_dia = 0;
        let total_cosecha_real = 0;
        let total_presupuesto = 0;
        let total_var_presupuesto = 0;
        let total_plan_maestro = 0;
        let total_var_plan_maestro = 0;

    
    // console.log(datos);
        (datos[datos.length - 1].filas).forEach(fila => {
            const { fecha, cosecha_a, cosecha_b, cosecha_dia, cosecha_real, 
            presupuesto, var_presupuesto, plan_maestro, var_plan_maestro } = fila;

            dias = [...dias, fecha.split('-')[2]];
            serie_real = [...serie_real, cosecha_real];
            serie_presupuesto = [...serie_presupuesto, presupuesto];
            serie_plan_maestro = [...serie_plan_maestro, plan_maestro];

            total_cosecha_a = total_cosecha_a + cosecha_a;
            total_cosecha_b = total_cosecha_b + cosecha_b;
            total_cosecha_dia = total_cosecha_dia + cosecha_dia;
            total_cosecha_real = cosecha_real;
            total_presupuesto = presupuesto;
            total_var_presupuesto = cosecha_real - presupuesto;
            total_plan_maestro = plan_maestro;
            total_var_plan_maestro = cosecha_real - plan_maestro;
        });


        return {
            nomina: {
                filas: datos[datos.length - 1].filas,
                fila_total: {
                    texto: 'MONTH TO DAY',
                    total_cosecha_a,
                    total_cosecha_b,
                    total_cosecha_dia,
                    total_cosecha_real,
                    total_presupuesto,
                    total_var_presupuesto,
                    total_plan_maestro,
                    total_var_plan_maestro
                }
            },
            grafico: {
                dias,
                serie_real,
                serie_presupuesto,
                serie_plan_maestro,
                total_cosecha_real,
                total_presupuesto,
                total_plan_maestro
            }
        }
    }

    const generarVariablesFila = ( fecha ) => {
        const fecha_fila = moment( fecha ).format( 'YYYY-MM-DD' );
        const mes_fila = ( fecha_fila ).split('-')[1];
        const dia_fila = ( ( fecha_fila ).split('-')[2])[0] == '0' ? ((fecha_fila).split('-')[2])[1] : (fecha_fila).split('-')[2];
        const ultimo_dia = (moment(fecha_fila).endOf('month')).date();

        return {
            fecha_fila,
            mes_fila,
            dia_fila,
            ultimo_dia
        }
    }

    const obtenerMesesCosecha = (datos) => {
        let num_meses = [];
        let meses = [];
        datos.forEach(({
            fecha
        }) => {
            const mes = (moment(fecha).format('YYYY-MM-DD')).split('-')[1];
            const {
                texto_mes
            } = obtenerMesAnio(moment(fecha).format('YYYY-MM-DD'));

            if (!num_meses.includes(mes)) {
                num_meses = [...num_meses, mes];
                meses = [...meses, {
                    num: mes,
                    mes: texto_mes
                }]
            }
            
        });

        return meses;
    }

    const cambiarInforme = (cont_origen, btn_origen, cont_nuevo, btn_nuevo) => {
        // Remover clase activo
        document.getElementById(cont_origen).classList.remove('activo');
        document.getElementById(btn_origen).classList.remove('activo');

        // Agregar clase activo
        document.getElementById(cont_nuevo).classList.add('activo');
        document.getElementById(btn_nuevo).classList.add('activo');
    }

    const generarSeriesMes = () => {
        const cant_dias = parseInt(moment().format('DD'));
        let real = [];
        let presupuesto = [];
        let plan_maestro = [];
        let dias = [];

        for (let i = 0; i < cant_dias; i++) {
            // Guardar fecha día
            dias = [...dias, i + 1];
            const valor = (Math.random() * 100).toFixed(2);
            real = [...real, valor];
            presupuesto = [...presupuesto, valor];
            plan_maestro = [...plan_maestro, (Math.random() * 100).toFixed(2)];
        }

        return {
            dias,
            real,
            presupuesto,
            plan_maestro
        }
    }

    const generarSeriesAnio = () => {
        const cant_meses = parseInt(moment().format('M'));
        let real = [];
        let presupuesto = [];
        let plan_maestro = [];
        let meses = [];

        for (let i = 0; i < cant_meses; i++) {
            let mes = i + 1 < 10 ? `0${i+1}` : i + 1;
            const {
                texto_mes
            } = obtenerMesAnio(`2023-${mes}-01`);

            // Guardar fecha día
            meses = [...meses, texto_mes];
            const valor = (Math.random() * 100).toFixed(2);
            real = [...real, valor];
            presupuesto = [...presupuesto, valor];
            plan_maestro = [...plan_maestro, (Math.random() * 100).toFixed(2)];
        }

        return {
            meses,
            real,
            presupuesto,
            plan_maestro
        }
    }

    const datosMes = () => {
        let filas = [];
        for (let i = 0; i < 31; i++) {
            const dia = i + 1 < 10 ? `0${i+1}` : i + 1;
            const fecha = `2023-07-${dia}`;
            filas = [...filas, {
                fecha,
                cosecha_dia: 100,
                cosecha_real: 100,
                presupuesto: 100,
                var_presupuesto: 100,
                plan_maestro: 100,
                var_plan_maestro: 100
            }];
        }

        return filas;
    }

    const datosAnio = () => {
        let filas = [];
        const cant_meses = parseInt(moment().format('M'));

        for (let i = 0; i < cant_meses; i++) {
            let mes = i + 1 < 10 ? `0${i+1}` : i + 1;
            const {
                texto_mes
            } = obtenerMesAnio(`2023-${mes}-01`);

            filas = [...filas, {
                fecha: texto_mes,
                cosecha_dia: 100,
                cosecha_real: 100,
                presupuesto: 100,
                var_presupuesto: 100,
                plan_maestro: 100,
                var_plan_maestro: 100
            }];
        }

        return filas;
    }

    const obtenerMesAnio = (fecha) => {
        const anio = fecha.split("-")[0];
        const mes = fecha.split("-")[1];
        let texto_mes = '';

        switch (mes) {
            case '01':
                texto_mes = 'Enero';
                break;

            case '02':
                texto_mes = 'Febrero';
                break;

            case '03':
                texto_mes = 'Marzo';
                break;

            case '04':
                texto_mes = 'Abril';
                break;

            case '05':
                texto_mes = 'Mayo';
                break;

            case '06':
                texto_mes = 'Junio';
                break;

            case '07':
                texto_mes = 'Julio';
                break;

            case '08':
                texto_mes = 'Agosto';
                break;

            case '09':
                texto_mes = 'Septiembre';
                break;

            case '10':
                texto_mes = 'Octubre';
                break;

            case '11':
                texto_mes = 'Noviembre';
                break;

            case '12':
                texto_mes = 'Diciembre';
                break;
        }

        return {
            texto_mes,
            anio
        }
    }

    return {
        obtenerCosechaProduccionAPI,
        obtenerSXAPI,
        obtenerEWSXAPI,
        obtenerLixAPI,
        obtenerDatosGlobablesAPI,

        guardarDatosGlobales,
        guardarCambios,

        obtenerMesAnio,
        cambiarInforme,
        generarSeriesAnio,
        generarSeriesMes,
        datosAnio,
        datosMes
    }
}