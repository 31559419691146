import '../styles/tabla.scss';


const TablaResumen = ({ datos, cabecera, formatoNum, vista }) => {
    const moment = require('moment');

    const obtenerPila = ( pila ) => {
        if( pila.includes("PILA") ) {
            return ''
        } else {
            const num = (pila.split(" "))[1]
    
            return num < 10 ? `0${num}` : num;
        }
    }

    const obtenerNumModulo = ( nombre, pila ) => {
        if( pila.includes("PILA") ) {
            return nombre.length == 1 ? `0${ nombre }` : nombre;

        } else {
            const num = (nombre[1]);
            const letra = nombre[0];
    
            if( letra == 'b' || letra == 'B') {
                return parseInt(num) + 4;
            } else {
                return num;
            }
        }
        
    }

    const obtenerNomenclatura = ( nomenclatura, ciclo, pila, modulo) => {
        if( nomenclatura != undefined ) {
            return nomenclatura;
        } else {
            return `${ ciclo }${ obtenerPila( pila )}${ obtenerNumModulo( modulo, pila ) }`
        }
    }

    return (
        datos.length == 0
        ?
            <div className="historico-vacio">
                Seleccione una planta, un ciclo y al menos una pila para obtener datos
            </div>
        :
            <div className="cont-tabla historico scroll">
                <table className='tabla-historico'>
                    <thead>
                        <tr>
                            {
                                cabecera.map(( { clases, nombre, unidad }, index ) => (
                                    <th key={ index } className={ clases } >
                                        { nombre } { unidad != '' ? <small>{ unidad }</small> : null }
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>

                    <tbody>
                        {
                            datos.map(( modulo, index ) => (
                                <tr key={ index }>
                                    {
                                        cabecera.map(( { tipo, llave }, subindex ) => (
                                            <td key={ subindex }>{ 
                                                modulo[llave] == null || modulo[llave] == '-'
                                                ? '-'
                                                :
                                                {   
                                                    'nomenclatura':
                                                        obtenerNomenclatura( modulo.nomenclatura, modulo.ciclo, modulo.nombre_pila, modulo.id_modulo_operacion ),

                                                    'fecha':
                                                        moment( modulo[llave], 'DD-MM-YYYY HH:mm:ss' ).format( 'DD-MM-YYYY HH:mm'),

                                                    'num':
                                                        formatoNum( parseFloat(modulo[llave] ) ),

                                                    'texto':
                                                        modulo[llave]
                                                }[tipo]
                                            }</td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
    )
}

export default TablaResumen